import React from "react";
import moment from "moment";

export default function Industry({
  arrow,
  title,
  subtitle,
  posts,
  goSingleArticle,
  goInsightsPage, 
  size
}) {


  return (
    <div className="IndustryHome">
      <div className="IndustryHome__Container">
        <h2>{title}</h2>
        <p className="Subtitle">{subtitle}</p>

        <div className="IndustryHomePosts">
          {posts &&
            posts.map((e) => {
  
              let data = JSON.parse(e.data);
              return (
                <div
                  key={e.id}
                  className="Post"
                  onClick={() =>
                    goSingleArticle(e.id)
                  }
                >
                  <img src={e.image_article} alt={data.tag} />
                  <div className="Info">
                    <div>
                      <p>{data.tag}</p>
                      <span>{moment.unix(e.date).format("ll")}</span>
                    </div>
                    <p>{data.title}</p>
                  </div>
                </div>
              );
            })}
        </div>
{
  size.width <=1200 &&(
    <div className="Button__container">
<button onClick={goInsightsPage}>
          View more <img src={arrow} alt="share" />
        </button>
        </div>
  )


}
          
      </div>
    </div>
  );
}
