import React from "react";
import Favorite from "../assets/images/about-page/mobile/favorite.png";
import FavoriteDesktop from "../assets/images/about-page/desktop/favorite.png";
import Phone from "../assets/images/about-page/mobile/iphone-favorite.png";
import PhoneDesktop from "../assets/images/about-page/desktop/iphone-favorite.png";
export default function AboutFavorite({ width }) {
  return (
    <div className="about-favorite">
      <div className="about-favorite__container">
        <div className="favorite-text">
          {width < 1200 ? (
            <img src={Favorite} alt="" />
          ) : (
            <img src={FavoriteDesktop} alt="" />
          )}
          <h3>Buy with Confidence</h3>
          <p>
            The Buyable platform provides insights on products and highlights
            companies that are locally made, environmentally conscious, and
            brands that care about what goes into their products.
          </p>
        </div>
        <div className="phone">
          {width < 1200 ? (
            <img src={Phone} alt="" />
          ) : (
            <img src={PhoneDesktop} alt="" />
          )}
        </div>
      </div>
    </div>
  );
}
