import React from "react";
import FacebookIcon from "../assets/images/singleArticle/Facebook.png";
import TwitterIcon from "../assets/images/singleArticle/Twitter.png";
import EmailIcon from "../assets/images/singleArticle/email-action-unread.png";
import LinkedinIcon from "../assets/images/singleArticle/Linkedin.png";
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from "react-share";

export default function Article({
  imageMobile,
  imageDesktop,
  size,
  buyable,
  insights,
  share,
  title,
  date,
  children,
  currentUrl,
  goSingleArticle,
  description =""
}) {

  return (
    <div className="SingleArticle">
      <div className="SingleArticle__Container">
      <p className="date">{date}</p>
        <h1>{title}</h1>
        <div className="Icons">
       <span></span>
          <div>

          <FacebookShareButton
              url={currentUrl}
             quote={title}
             hashtag="#ShopSmart."
             >
              <img src={FacebookIcon} alt=""/>
            </FacebookShareButton>
            <TwitterShareButton
              url={currentUrl}
             title={title}
             hashtags={["ShopSmart.", "Buyable", "GetTheFacts"]}
             >
              <img src={TwitterIcon} alt=""/>
            </TwitterShareButton>
            <EmailShareButton
               url={currentUrl}
              subject={title}
              body={description}
              separator="        "
             >
              <img src={EmailIcon} alt=""/>
            </EmailShareButton>
            <LinkedinShareButton
                url={currentUrl}
              title={title}
              summary={description}
            
              
             >
              <img src={LinkedinIcon} alt=""/>
            </LinkedinShareButton>
          </div>
        </div>
        <div className="Image">
      
          {size.width >= 1200 ? (
            <img
              src={imageDesktop}
              alt="article-
              img"
            />
          ) : (
            <img src={imageMobile} alt="article-img" />
          )}
        </div>
        <div className="Text">{children}</div>
        <div className="Logo">
        <div>

<FacebookShareButton
    url={currentUrl}
   quote={title}
   hashtag="#ShopSmart."
   >
    <img src={FacebookIcon} alt=""/>
  </FacebookShareButton>
  <TwitterShareButton
    url={currentUrl}
   title={title}
   hashtags={["ShopSmart.", "Buyable", "GetTheFacts"]}
   >
    <img src={TwitterIcon} alt=""/>
  </TwitterShareButton>
  <EmailShareButton
     url={currentUrl}
    subject={title}
    body={description}
    separator="        "
   >
    <img src={EmailIcon} alt=""/>
  </EmailShareButton>
  <LinkedinShareButton
      url={currentUrl}
    title={title}
    summary={description}
  
    
   >
    <img src={LinkedinIcon} alt=""/>
  </LinkedinShareButton>
</div>
          <img src={buyable} alt="brand" className="Brand" />
        </div>

     
      </div>
    </div>
  );
}
