import React from 'react'
import BuyableFavorite from "../assets/images/brand-page/favorite.png";

export default function CoverImageHeader({coverImage, alt=""}) {
    return (
        <div className="CoverImageHeader__Container">
            <div className="CoverImage">
                <img src={coverImage} alt={alt} />
            </div>
           <img src={BuyableFavorite} alt={alt} className="Favorite"/>
          
        </div>

    )
}
