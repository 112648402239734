import React from "react";
import ConsumerDesktop from "../../src/assets/images/about-page/desktop/consumer.png";
import Consumer from "../../src/assets/images/about-page/mobile/consumer.png";
import StarsVertical from "../../src/assets/images/about-page/mobile/vertical-stars.png";
import StarsHorizontal from "../../src/assets/images/about-page/desktop/horizontal-stars.png";

export default function AboutConsumer({ width }) {
  return (
    <div className="about-consumer">
      <div className="about-consumer__container">
        <div className="grid">
          <div className="images">
            {width < 1200 ? (
              <>
                <img src={Consumer} alt="" />
                <img src={StarsVertical} alt="" />
              </>
            ) : (
              <>
                <img src={ConsumerDesktop} alt="" />
                <img src={StarsHorizontal} alt="" />
              </>
            )}
          </div>
          <div className="text">
            <h2>What we noticed</h2>
            <p>
              It’s 2021. There are millions of brands out there, but how do you
              know what’s really in your product? Where was it actually made? Is
              it safe to use? People are starting to care about the brands they
              purchase from, and ultimately support companies that align with
              their standards.
            </p>
            <p>
              We noticed there is a gap in the transparancy and quality control
              between brands to consumers, especially with foreign made
              products. There is no one true source to get reliable, detailed
              information on consumer products.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
