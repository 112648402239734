import React from "react";


export default function ShopHomeSection({
  arrow,
  title,
  paragraph,
  buttonText,
  beauty,
  food,
  medical,
  goShop,
  size,
}) {
  return (
    <div className="ShopHomeSection">
      <div className="ShopHomeSection__Container">
        <div className="ShopHomeSection__CTA">
          <p>{paragraph}</p>
          <h6>{title}</h6>
          {
            size.width >= 1200 && (
              <button onClick={goShop}>
              {buttonText}
              <img src={arrow} alt={title} />
            </button>
            )
          }
        
        </div>

        <div className="ShopHomeSection__Images">
          <img
            src={beauty}
            alt="beauty"
            className="Beauty"
            onClick={() => goShop("beauty")}
          />

          <img
            src={food}
            alt="food"
            className="Food"
            onClick={() => goShop("food")}
          />

          <img
            src={medical}
            alt="medical"
            className="Medical"
            onClick={() => goShop("beauty")}
          />
        </div>


        {
            size.width< 1200 && (
              <div className="ButtonMobile__container">
<button onClick={goShop}>
              {buttonText}
              <img src={arrow} alt={title} />
            </button>
              </div>
              
            )
          }
        

      </div>
    </div>
  );
}
