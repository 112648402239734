import React, {useCallback, useState, useEffect, useRef} from "react";
import BuyableLogo from "../assets/images/new-home/buyable-logo.svg";
import AutoComplete from "./AutoComplete";
import Navbar from "./Navbar";
import Footer from "./NewFooter";
import { useRouter } from "./../util/router.js";
import localAuth from "../util/local-auth.js";

export default function Layout({
  children,


}) {
  const router = useRouter();
  const [products, setProducts] = useState(false);
  const [value, setValue] = useState("");
  const valueRef = useRef("");
  const goToVerifiedBrandsPage = (path) => {
    if (router.pathname !== path) router.push(path);
  };
  const sendValue = (e) => {
    e.preventDefault();
    setValue(valueRef.current.value);
    localStorage.setItem("search", valueRef.current.value);
    localStorage.setItem("pushed",true)
    goToVerifiedBrandsPage("/shop")
    setTimeout(setValue(""), 1000)
  };
  const getProducts = useCallback(async (value) => {
    try {
      const response = await localAuth.searchProducts({ name: value });
      setProducts(response.data);
    } catch (error) {
      return error;
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
       
      getProducts(value);
      return () => {
        isMounted = false;
      };
    }
  }, [getProducts, value]);

  return (
    <>
      <Navbar color="default" logo={BuyableLogo} />
      {router.pathname !== "/shop" && (
        <AutoComplete
          slogan="Get the facts. Shop smart."
          valueRef={valueRef}
          sendValue={sendValue}
          value={value}
          products={products}
          getProducts={getProducts}
          setValue={setValue}
        />
      )}

      {children}

     
      <Footer appStoreUrl="https://apps.apple.com/us/app/buyable-shop-smarter/id1541213172" />
    </>
  );
}
