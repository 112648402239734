import React from "react";

export default function ProductsSection({
  type = "",
  appearance = "",
  pathname,
  company = false,
  goProductPage,
  amazonProducts = [],
}) {
 
  return (
    <section className="Products" id="products">
      <div className="Products__Container">
        {appearance === "product-page" && amazonProducts.length === 0 ? (
          ""
        ) : appearance === "product-page" && amazonProducts.length > 0 ? (
          <h3>View other products from this brand</h3>
        ) : (
          <h2> Shop Products</h2>
        )}

        <div className={`ProductsGrid--${type}`}>
          {amazonProducts &&
            amazonProducts.length > 0 &&
            amazonProducts.map(({ imageUrl, name, id }) => (
              <div className="Product__Container">
                {imageUrl && (
                  <div
                    className="Product"
                    index={id}
                    onClick={() =>
                      goProductPage(
                        `${pathname.replace(
                          "false",
                          "true"
                        )}/product/${id}`
                      )
                    }
                  >
                    <img src={imageUrl} alt={name} />
                  </div>
                )}
                <h4>{name}</h4>
                <p>{company}</p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
