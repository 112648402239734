import React from "react";
import AboutHero from "../components/AboutHero";
import { useSize } from "../util/custom-hooks/browsersize";
import AboutInfo from "../components/AboutInfo";
import AboutConsumer from "../components/AboutConsumer";
import AboutSolution from "../components/AboutSolution";
import AboutFavorite from "../components/AboutFavorite";
import AppStoreSection from "../components/AppStoreSection";
import { items } from "../data/about/problemItem.js";
import AboutScan from "../components/AboutScan";
import AppStoreLogo from "../assets/images/new-home/App Store.svg";
export default function About() {
  const size = useSize();
  return (
    <>
      <AboutHero {...size} />
      <AboutInfo items={items} {...size} />
      <AboutConsumer {...size} />
      <AboutSolution {...size} />
      <AboutFavorite {...size} />
      <AboutScan {...size} />
      <AppStoreSection
       icon={AppStoreLogo}
       href="https://apps.apple.com/us/app/buyable-shop-smarter/id1541213172"
       heading="Get Involved."
       paragraph="Help make a difference in the transparency of products."/>
    </>
  );
}
