import Product1 from "../../assets/images/brand-page/beauty/product.png";


 const products = [
  { 
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },
  {
    productImage: Product1,
    detail: "Milky Jelly Cleanser",

 
  },

  

];

export { products };
