

import React from 'react'
import Loader from "react-loader-spinner";

function LoaderComponent({appearance=false, timeout=false}) {
    return (
        <div className={`Loader__Container
 ${appearance ? `Loader__Container--${appearance}`:""}`}>
        <Loader
        type="Grid"
        color="#000000"
        height={60}
        width={60}
        timeout={timeout ? timeout:1000} 
      />

</div>
    )
}
export default  LoaderComponent;

