import React from "react";
import { Link } from "../util/router.js";
import Fade from "react-reveal/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Loader from "../components/Loader";
import SulfateFreeIcon from "../assets/images/shop/sulfatefreebrandcard.svg";
import CrueltyFreeIcon from "../assets/images/shop/cruelty-free.png";
import FSCIcon from "../assets/images/shop/fsc.png";
import LeapingBunnyIcon from "../assets/images/shop/lepping.png";
import OrganicIcon from "../assets/images/shop/organic.png";
import ParabenFreeIcon from "../assets/images/shop/pfree.png";
import DairyFreeIcon from "../assets/images/shop/lactose-free.png";
import DairyFreeIconPlus from "../assets/images/shop/lactose-free-plus.png";
import VeganIcon from "../assets/images/shop/vegan.png";
import GlutenFreeIcon from "../assets/images/shop/gluten-free.png";
import GlutenFreeIconPlus from "../assets/images/shop/gluten-free-plus.png";
import VeganPlusIcon from "../assets/images/shop/veganv.png";
import USDAIcon from "../assets/images/shop/USDA.png";
import GlassdoorIcon from "../assets/images/shop/glassdor.png";
import BBBIcon from "../assets/images/shop/144.png";
import EWG from "../assets/images/shop/EWG1.png";
import Bcorp from "../assets/images/shop/b-corp-large.png";
import VerifiedLogo from "../assets/images/verified-page/Component 1.png"

const useStyles = makeStyles((theme) => ({
  buttonControl: {
    margin: " 0",
    background:'transparent',
    minWidth: 105,
    height:'38.7px',
    borderRadius:'30px',
    border:'1px solid #000000',
    textTransform:'capitalize',
    "@media (min-width: 992px)": {
     display: 'none',
    },
  },
}));

export default function SectionCollections({
  dataCollection,
  handleChange,
  loading,
  defaultValue,
  onToggleModal
}) {

  const classes = useStyles();
  return (
    <Fade delay={100}>
      <section className="">
        <div className="Collection__Filter" >
          <h2>Verified Brands</h2>
     {/* <FormControl variant="filled" className={classes.formControl}>
          <NativeSelect
              className={classes.selectEmpty}
              name="options"
              value={defaultValue}
              onChange={handleChange}
              inputProps={{
                name: "options",
                id: "filled-age-native-simple",
              }}
            >
              <option value="" placeholder disabled>
                Category
              </option>

              <option value={"all"}>View All</option>
              <option value={"beauty"}>Beauty/Skincare</option>
              <option value={"food"}>Food/Beverage</option>
              <option value={"pet-care"}>Pet Care</option>
            </NativeSelect>
          </FormControl>*/}
          <Button className={classes.buttonControl} onClick={onToggleModal}>Sort & Filter</Button>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="Collection__Container">
            {dataCollection &&
              dataCollection.map((e) => (
                <Link
                  to={`/${
                    e.categories[0]?.name.toLowerCase() === "beauty"
                      ? "beauty-and-skincare"
                      :
                      e.categories[0]?.name.toLowerCase() === "pet care"
                      ? "pet-care":
                      e.categories[0]?.name.toLowerCase()
                  }/${e.id}/redirect=${false}`}
                  className="Link"
                >
            {/* //TODO Make dynamic verified logo*/}
                  <div
                    className={`Card__Container Card__Container--verified ${
                      e.approved ? `Card__Container--approved` : ""
                    }`}
                  >
                    {e.approved && (
                      <div className="Approved">Buyable Favorite</div>
                    )}
                    <div className="Verified">
                      <img src={VerifiedLogo} alt=""/>
                    </div>
                    <div className="Logo__Container">
                      <img src={`${process.env.REACT_APP_SWAGGER_URL}/company/image/${e.id}`} alt={e.name} />
                    </div>
                    <div className="Text__Container">
                      <div>
                        <h5>
                          {e.name}
                        </h5>

                        <p>Manufacturing Country</p>
                        <p>United States</p>
                      </div>
                      <div className="Ratings">
                        {e.globalRatings.map((tag) => (
                          <>
                            {tag.name === "BBB Accredited" && tag.present && (
                              <img
                                className="TagImg TagBBB"
                                src={BBBIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "B-Corp Certified" && tag.present && (
                              <img
                                className="TagImg TagBCorp"
                                src={Bcorp}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "EWG Certified" && tag.present && (
                              <img
                                className="TagImg"
                                src={EWG}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Glassdoor Rating" && tag.score && (
                              <img
                                className="TagImg"
                                src={GlassdoorIcon}
                                alt={tag.name}
                              />
                            )}
                          </>
                        ))}
                        {e.categories[0]?.ratings.map((tag) => (
                          <>
                            {tag.name === "Cruelty Free" && (
                              <img
                                className="TagImg"
                                src={CrueltyFreeIcon}
                                alt={tag.name}
                              />
                            )}

                            {tag.name === "Sulfate Free" && (
                              <img
                                className="TagImg"
                                src={SulfateFreeIcon}
                                alt={tag.name}
                              />
                            )}

                            {tag.name === "FSC Certified" && (
                              <img
                                className="TagImg"
                                src={FSCIcon}
                                alt={tag.name}
                              />
                            )}

                            {tag.name === "Leaping Bunny Certified" && (
                              <img
                                className="TagImg"
                                src={LeapingBunnyIcon}
                                alt={tag.name}
                              />
                            )}

                            {tag.name === "Organic" && (
                              <img
                                className="TagImg"
                                src={OrganicIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Paraben Free" && (
                              <img
                                className="TagImg"
                                src={ParabenFreeIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Dairy-Free" && (
                              <img
                                className="TagImg"
                                src={DairyFreeIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Dairy-Free Products Available" && (
                              <img
                                className="TagImg"
                                src={DairyFreeIconPlus}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Vegan" && (
                              <img
                                className="TagImg"
                                src={VeganIcon}
                                alt={tag.name}
                              />
                            )}

                            {tag.name === "Gluten-Free" && (
                              <img
                                className="TagImg"
                                src={GlutenFreeIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Gluten-Free Products Available" && (
                              <img
                                className="TagImg"
                                src={GlutenFreeIconPlus}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "Vegan Products Available" && (
                              <img
                                className="TagImg"
                                src={VeganPlusIcon}
                                alt={tag.name}
                              />
                            )}
                            {tag.name === "USDA Certified" && (
                              <img
                                className="TagImg"
                                src={USDAIcon}
                                alt={tag.name}
                              />
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        )}
      </section>
    </Fade>
  );
}
