import React from "react";
import { Switch, Route, Router } from "./../util/router.js";

import IndexPage from "./index";
import IndustryInsightsPage from "./industryInsights";
import NotFoundPage from "./not-found.js";
import Layout from "../components/Layout";
import BeautyBrandPage from "./beautyBrand";
import PetBrandPage from "./petBrand";
import FoodBrandPage from "./foodBrand";
import ProductPage from "./product";
import RatingSystemPage from "./ratingSystem";
import SingleArticle from "./singleArticle";
import ShopPage from "./shop";
import VerifiedBrandsPage from "./verifiedBrands.js";
import AboutPage from "./about";

function App() {
  return (

      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/food/:brand/:redirect" component={FoodBrandPage} />
            <Route
              exact
              path="/beauty-and-skincare/:brand/:redirect"
              component={BeautyBrandPage}
            />
            <Route exact path="/pet-care/:brand/:redirect" component={PetBrandPage} />
            <Route exact path="/certifications" component={RatingSystemPage} />
            <Route
              exact
              path="/verified-brands/"
              component={VerifiedBrandsPage}
            />
            <Route
              exact
              path="/verified-brands/:category"
              component={VerifiedBrandsPage}
            />
            <Route exact path="/shop" component={ShopPage} />
            <Route
              exact
              path="/industry-insights"
              component={IndustryInsightsPage}
            />
            <Route
              exact
              path="/industry-insights/post/:id"
              component={SingleArticle}
            />
            <Route
              exact
              path="/:category/:brandId/:redirect/product/:id"
              component={ProductPage}
            />
            <Route
              exact
              path="/industry-insights"
              component={IndustryInsightsPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </Router>
  
  );
}

export default App;
