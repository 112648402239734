import React, { useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import MinimizeIcon from "@material-ui/icons/Minimize";
import AddIcon from "@material-ui/icons/Add";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const BlackCheckbox = withStyles({
  root: {
    color: "black",
    "&$checked": {
      color: "black",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    width: "100%",
    marginTop: "3rem",
  },

  heading: {
    fontSize: "14px",
    fontFamily: "Helvetica Neue",
    fontWeight: "bold",
    lineHeight: "17px;",
    color: "#000000",
    "@media (min-width: 992px)": {},
  },
  minimizeIcon: {
    position: "relative",
    top: "-9px",
  },
  label: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal ",
    fontWeight: "normal ",
    fontSize: "14px ",
    lineHeight: "17px ",
    color: "#000000",
    maxHeight: "24px",
  },
  details: {
    padding: "8px 6px 16px",
  },
  summaryOne: {
    borderTop: "1px solid #D5D5D5",
    padding: "0 ",
    "@media (min-width: 992px)": {
      padding: "0 8px",
    },
  },
  summary: {
    padding: "0 ",
    "@media (min-width: 992px)": {
      padding: "0 8px",
    },
  },
  searchButton: {
    background: "#54EFB7",
    boxSizing: "border-box",
    borderRadius: "30px",
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    lineHeight: "17px",
    minWidth: "137px",
    height: "38px",
    "&:hover": {
      background: "#54EFB7",
    },
  },
  clearButton: {
    border: "1px solid #54EFB7",
    boxSizing: "border-box",
    borderRadius: "30px",
    textTransform: "capitalize",
    color: "#54EFB7",
    fontFamily: "Helvetica Neue",
    fontSize: "14px",
    lineHeight: "17px",
    minWidth: "137px",
    height: "38px",
    marginRight: "5vw",
  },
  rowButtons: {
    marginTop: "54px",
    display: "flex",
    justifyContent: "center",
  },
}));

export default function SimpleAccordion({
  modal = false,
  handleChangeCategory =false,
  handleChangeBeauty=false,
  handleChangeFood=false,
  stateCategory,
  clearCheckbox,
  stateSearch,
  handleChangeAll,
  handleChangeVerified,
  handleChangeFavorites,
  handleChangePet=false,
  verifiedBrands= false
}) {
 
  const classes = useStyles();
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [categoryExpanded, setCategoryExpanded] = useState(false);

  return (
    <div className={`${classes.root} Accordion__Container`}>
      <Accordion onChange={(e, expanded) => setSearchExpanded(expanded)}>
        <AccordionSummary
          className={classes.summaryOne}
          expandIcon={
            searchExpanded ? (
              <MinimizeIcon className={classes.minimizeIcon} />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p className={classes.heading}>Search by</p>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <FormGroup>
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  checked={stateSearch.checkedFavorites}
                    onChange={handleChangeFavorites}
                  name="checkedFavorites"
                />
              }
              label="Buyable Favorites"
            />
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  checked={stateSearch.checkedBrands}
                  onChange={handleChangeVerified}
                  name="checkedBrands"
                />
              }
              label="Verified Brands"
            />
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  checked={stateSearch.checkedAll}
                  onChange={verifiedBrands? handleChangeVerified : handleChangeAll}
                  name="checkedAll"
                />
              }
              label="Shop All"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={(e, expanded) => setCategoryExpanded(expanded)}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={
            categoryExpanded ? (
              <MinimizeIcon className={classes.minimizeIcon} />
            ) : (
              <AddIcon />
            )
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Category</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <FormGroup>
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  id="2"
                  
                  checked={verifiedBrands ?stateCategory.checkedBeauty:stateCategory.checkedBeauty[0]}
                  onChange={verifiedBrands ? handleChangeBeauty: handleChangeCategory}
                  name="checkedBeauty"
                />
              }
              label="Beauty/Skincare"
            />
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  id="1"
                  checked={verifiedBrands? stateCategory.checkedFood:stateCategory.checkedFood[0]}
                  onChange={verifiedBrands? handleChangeFood:handleChangeCategory}
                  name="checkedFood"
                />
              }
              label="Food"
            />
            <FormControlLabel
              className={classes.label}
              control={
                <BlackCheckbox
                  id="9"
                  checked={verifiedBrands? stateCategory.checkedPetCare:stateCategory.checkedPetCare[0]}
                  onChange={verifiedBrands ? handleChangePet:handleChangeCategory}
                  name="checkedPetCare"
                />
              }
              label="Petcare"
            />
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      {modal && (
        <div row className={classes.rowButtons}>
          <Button className={classes.clearButton} onClick={clearCheckbox}>
            Clear Filters
          </Button>
          <Button className={classes.searchButton}>Search</Button>
        </div>
      )}
    </div>
  );
}
