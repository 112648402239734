


export function apiRequest(path, method = "GET", data, returnAllResponse) {

  return fetch(process.env.REACT_APP_SWAGGER_URL+`/${path}`, {
    method: method,
    headers: {
      "Content-Type": "application/json"
    
    },
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response.json())
    .then((response) => {

 
      if (response.status === "error") {


        throw new CustomError(response.code, response.message);
      } else {
        return (returnAllResponse) ? response : (response);
      }
    }).catch((error) => {
      console.log("Error calling endpoint");
      console.log(error);
    });
}


export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}
