import Rating1 from "../../assets/images/rates-page/bbb.png";
import Rating2 from "../../assets/images/rates-page/certified-b-corporation-vector-logo 1.png";
import Rating3 from "../../assets/images/rates-page/ESG.png";
import Rating4 from "../../assets/images/rates-page/EWG-RatingsPage-Mobile.png";
import Rating5 from "../../assets/images/rates-page/glassdoor-logo2.png";

const globalRatings = [
  {
    name: "BBB Accredited",
    ratingImage: Rating1,
    detail:
      "The Better Business Bureau (BBB), is a private, nonprofit organization. Their mission is to focus on advancing marketplace trust. The BBB rating system rates companes A+ - F. The grades represent BBB's degree of confidence that the business is operating in a trustworthy manner and will make a good faith effort to resolve any customer concerns filed with the BBB. ",
  },
  {
    name: "B-Corp Certified",
    ratingImage: Rating2,
    detail:
      "B-Corp Certified brands are companies that are certified by B Lab as meeting specific standards of social and envrionmental performance, accountability and transparency.",
  },
  {
    name: "ESG verified",
    ratingImage: Rating3,
    detail:
      "Environmental, Social, and Corporate Governance (ESG) refers to the three central factors in measuring the sustainability and societal impact of an investment in a company or business. These criteria help to better determine the future financial performance of companies (return and risk).",
  },
  {
    name: "EWG verified",
    ratingImage: Rating4,
    detail:
      "ESG verified was created to drive companies to make safer and healthier products with an ultimate goal of making all products on the market to be free of harmful chemical. If a company is EWG verified, it has met EWG’s strict criteria including; avoids EWG’s ingredients of concern, provides full transparency & uses good manufacturating practices.",
  },
  {
    name: "Glassdoor Rating",
    ratingImage: Rating5,
    detail:
      "Glassdoor provides insights and full transparency on company employee practices using a point system where company’s  are given a score out of five. They serve as job board where employers can post job listings as well as review site for employers.",
  },
];

export { globalRatings };
