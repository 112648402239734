import React, { useState, useCallback, useEffect, useRef } from "react";
import { Modal } from "react-responsive-modal";
import CloseIcon from "../assets/images/verified-page/close.png";
import AccordionFilter from "../components/AccordionFilter";
import SectionProducts from "../components/SectionProducts";
import AutoComplete from "../components/AutoComplete";
import localAuth from "../util/local-auth.js";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import BreadCrumbIcon from "../assets/images/breadcrumb/Vector.png";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.5rem 2rem",
    "@media (min-width: 992px)": {
   padding:0
    },
  },
  prevLink: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '10px',
    lineHeight: '11px',
    textDecorationLine: 'underline',
    color: '#000000'


  },
  current: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '10px',
    lineHeight: '11px',
    color: '#000000',
   '&:hover':{
    textDecorationLine:'none'
   }
  },
  separator: {
 
  }
}));

function Shop() {
  const [value, setValue] = useState("");
  const classes = useStyles();
  const valueRef = useRef("");
  const [searched, setSearched] = useState(false)

  const sendValue = (e) => {
    e.preventDefault();
    setSearched(true)
    setValue(valueRef.current.value);
    getProducts(valueRef.current.value);
    clearFilters(true);
    setTimeout(setValue(""), 500);
  };
  const valueSearched = localStorage.getItem("search");
  const pushed = localStorage.getItem("pushed") || false;
  const [openFilters, setOpenFilters] = useState(false);
  const [options, setOptions] = useState(false);
  const onToggleModal = () => setOpenFilters(!openFilters);
  const [shopProducts, setShopProducts] = useState(false);

  const [stateSearch, setStateSearch] = useState({
    checkedFavorites: false,
    checkedBrands: false,
    checkedAll: false,
  });
  const [stateCategory, setStateCategory] = useState({
    checkedBeauty: [false, false],
    checkedFood: [false, false],
    checkedPetCare: [false, false],
  });

  const clearFilters = (search = false) => {
    setStateCategory({
      checkedBeauty: [false, false],
      checkedFood: [false, false],
      checkedPetCare: [false, false],
    });
    setStateSearch({
      checkedFavorites: false,
      checkedBrands: false,
      checkedAll: false,
    });
    if (search) return;
    getProducts("");
  };

  const idsBuilder = (objectCategories, verified = false) => {
    let arrayIds = [];
    for (const category in objectCategories) {
      let array = Array.from(objectCategories[category]);
      if (array[0]) arrayIds.push(array[1]);
    }
    getProducts(value, arrayIds, verified);
  };

  const getAllOptions = useCallback(async () => {
    try {
      const response = await localAuth.searchProducts({ name: "" });

      if (response.success) {

        setOptions(response.data);
      }
    } catch (error) {
      return error;
    }
  }, []);

  const getProducts = useCallback(
    async (searchValue, arrayIds = false, verified = false) => {
      localStorage.setItem("search", "");
      localStorage.setItem("valueSearched", "");
      if(searchValue !=="") setSearched(true)
      let data = {
        name: searchValue,
        favorite: false,
        verified: verified,
      };
      if (arrayIds && arrayIds?.length > 0) data.categories = arrayIds;

      try {
        const response = await localAuth.searchProducts(data);
        setShopProducts(response.data);

      } catch (error) {
        return error;
      }
    },
    []
  );

  const handleChangeFavorites = (event) => {
    setStateSearch({
      checkedBrands: stateSearch.checkedBrands,
      checkedAll: false,
      [event.target.name]: event.target.checked,
    });
    getProducts("");

  };

  const handleChangeAll = async (event) => {
    setStateCategory({
      checkedBeauty: [false, false],
      checkedFood: [false, false],
      checkedPetCare: [false, false],
    });
    setStateSearch({
      checkedBrands: false,
      checkedFavorites: false,
      [event.target.name]: event.target.checked,
    });
    getProducts("");

  };

  const handleChangeVerified = async (event) => {
    setStateSearch({
      checkedBrands: event.target.checked,
      checkedAll: false,
      checkedFavorites: stateSearch.checkedFavorites,
    });
    idsBuilder(stateCategory, event.target.checked);
 
  };

  const handleChangeCategory = (event) => {
    setStateSearch({
      checkedFavorites: stateSearch.checkedFavorites,
      checkedBrands: stateSearch.checkedBrands,
      checkedAll: false,
    });

    setStateCategory({
      ...stateCategory,
      [event.target.name]: [event.target.checked, event.target.id],
    });

    idsBuilder({
      ...stateCategory,
      [event.target.name]: [event.target.checked, event.target.id],
    });

  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAllOptions();
      getProducts(
        pushed === "true" && valueSearched !== "" ? valueSearched : ""
      );
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllOptions,  getProducts]);

  return (
    <>
      <AutoComplete
        slogan="Get the facts. Shop smart."
        valueRef={valueRef}
        sendValue={sendValue}
        value={value}
        products={options}
        getProducts={getProducts}
        avoidPush
        setValue={setValue}
      />

      <div className="VerifiedBrands__Grid">

        <div>

          {
            ( searched) && (
              <div  className={classes.root}>
              <Breadcrumbs separator={<img src={BreadCrumbIcon} alt="" className={classes.separator} />} aria-label="breadcrumb">
                <Link color="inherit" href="/shop" className={classes.prevLink}>
                  Shop
                </Link>

                <Link className={classes.current}>Search Results</Link>
              </Breadcrumbs>
              </div>
            )
          }
          <AccordionFilter
            clearCheckbox={clearFilters}
            stateCategory={stateCategory}
            stateSearch={stateSearch}
            handleChangeFavorites={handleChangeFavorites}
            handleChangeCategory={handleChangeCategory}
            handleChangeAll={handleChangeAll}
            handleChangeVerified={handleChangeVerified}
          />
        </div>
        <SectionProducts
          dataCollection={shopProducts}
          onToggleModal={onToggleModal}
        />
        <Modal
          open={openFilters}
          onClose={onToggleModal}
          classNames={{
            overlay: 'customOverlay',
            modal: 'customModalFilter',
          }}
          closeIcon={<img src={CloseIcon} alt="" />}
        >
          <AccordionFilter
            clearCheckbox={clearFilters}
            stateSearch={stateSearch}
            stateCategory={stateCategory}
            handleChangeFavorites={handleChangeFavorites}
            handleChangeCategory={handleChangeCategory}
            handleChangeAll={handleChangeAll}
            handleChangeVerified={handleChangeVerified}
            modal={true}
          />
        </Modal>
      </div>
    </>
  );
}
export default Shop;
