import Rating2 from "../../assets/images/rates-page/beauty/cruelty-free.png";
import Rating3 from "../../assets/images/rates-page/beauty/fsc.png";
import Rating4 from "../../assets/images/rates-page/beauty/leaping-bunny-approved-vector-logo.png";
import Rating5 from "../../assets/images/rates-page/beauty/organic.png";
import Rating6 from "../../assets/images/rates-page/beauty/parabenfree.png";
import Rating7 from "../../assets/images/rates-page/beauty/sulfatefree.png";
import Rating8 from "../../assets/images/rates-page/beauty/vegan.png";
import Rating9 from "../../assets/images/rates-page/beauty/veganv.png";


const categoryRatingsBeauty = [
  {
    name: "Cruelty Free",
    ratingImage: Rating2,
    detail:
      "A product is cruelty free when it is not tested on amials.",
  },
  {
    name: "FSC Certified",
    ratingImage: Rating3,
    detail:
      "FSC certification makes sure that products come from responsibly managed forests that provide environmental, social and economic benefits. ",
  },
  {
    name: "Leaping Bunny Certified",
    ratingImage: Rating4,
    detail:
      "Leaping Bunny is known to be the most trusted cruelty free certification for non-animal tested products. Achieving the certification tells consumers that their procedures and policies have been verified to truly be cruelty-free.",
  },
  {
    name: "Organic",
    ratingImage: Rating5,
    detail:
      "The products are made with all organic ingredients.",
  },
  {
    name: "Paraben Free",
    ratingImage: Rating6,
    detail:
      "Products contain no parabens - potentially harmful chemical preservatives that are used in some beauty products. ",
  },
  {
    name: "Sulfate Free",
    ratingImage: Rating7,
    detail:
      "Products contain no sulfates - chemicals known as surfactants or faoming agents that are good for removing dirt and oil. Products with sulfate may deal to irritated skin.  ",
  },
  {
    name: "Vegan",
    ratingImage: Rating8,
    detail:
      "Products that do not contain any ingredients that are derived from animals. ",
  },
  {
    name: "Vegan Products Available",
    ratingImage: Rating9,
    detail:
      "A company offers vegan products. ",
  }
  
  
  
];

export { categoryRatingsBeauty };
