import { apiRequest } from "./util.js";

const requests= {
  login: (data) => {
    return apiRequest("user/login", "POST", data);
  },
  getCategories: () => {
    return apiRequest("category/all-with-ratings-and-companies", "GET")
  },
  addCategory: (data) => {
    return apiRequest("category/add-with-ratings", "POST", data)
  },
  addCompany: (data) => {
    return apiRequest("company/add", "POST", data)
  },
  getCompanies: () => {
    return apiRequest("company/all", "GET")
  },
  getCompany: (id) => {
    return apiRequest("company/id/" + id, "GET")
  },
  getCategoryRating: (id) => {
    return apiRequest("category-rating/category/" + id, "GET")
  },
  addAdditionalRating: (data) => {
    return apiRequest("rating/add", "POST", data)
  },
  addRatingToCategory : (data) =>{
    return apiRequest("category-rating/add", "POST", data)
  },
  editRatings : (data) =>{
    return apiRequest("company/add-category-rating-array", "POST", data)
  },
  editCompany : (data) =>{
    return apiRequest("company/edit", "PUT", data)
  },
  addCategoryAndRatingToCompany: (data) =>{
    return apiRequest("company/add-category-rating-array", "POST", data)
  },
  getAllGlobalRatings: ()=>{
    return  apiRequest("global-rating/all", "GET")
  },
  addGlobalRatingsToCompany: (data) =>{
    return apiRequest("global-rating/add-array", "POST", data)
  },
  getCompanyGlobalRatings:(id) =>{
    return  apiRequest("global-rating/id/"+ id, "GET")
  },
  getAmazonProducts:(id) => {
    return  apiRequest("product/company-id/"+ id, "GET")
  },
  getProduct:(id) => {
    return  apiRequest("product/id/"+ id, "GET")
  },
  getAllArticles: ()=>{
    return  apiRequest("article", "GET")
  },
  getArticle:(id) => {
    return  apiRequest("article/id/"+ id, "GET")
  },
  searchProducts:(data) =>{
    return  apiRequest("product/search", "POST", data)
  },
  getApprovedContent:(id) =>{
    return  apiRequest("company/approved-content/"+  id, "GET")
  },
  getArticleImage: (id) =>{
    return apiRequest("article/image/" + id, "GET")
  },
  saveEmail:(data)=>{
    return apiRequest("user/email", "POST", data)
  }
};
export default requests;