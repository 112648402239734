import React from "react";
import ImgSearch from "../assets/images/about-page/desktop/search.png";

export default function AboutInfo({ width, items }) {
  return (
    <div className="about-info">
      <div className="about-info__container">
        <div className="main-grid">
          <div className="text__container">
            <h2>Current Database Problems </h2>

            <div className="items-grid">
              {items.map(({ title, icon, iconDesktop, text }, i) => (
                <div key={i} className="item">
                  {width < 1200 ? (
                    <div>
                      <img src={icon} alt={title} />
                    </div>
                  ) : (
                    <div>
                      <img src={iconDesktop} alt={title} />
                    </div>
                  )}
                  <h5>{title}</h5>
                  <p>{text}</p>
                </div>
              ))}
            </div>
          </div>
          {width >= 1023 && 
          (<div className="search__container">
              <img src={ImgSearch} alt="" className="search" />
            </div>

         )}
        </div>
      </div>
    </div>
  );
}
