import React from "react";
import ScanPhone from "../assets/images/about-page/mobile/scan.png";
import ScanPhoneDesktop from "../assets/images/about-page/desktop/scan.png";

export default function AboutScan({ width }) {
  return (
    <div
      className="about-scan
        "
    >
      <div className="about-scan__container">
        <div className="text">
          <h6>Support Transparency</h6>
          <h2>Scan and verify manufacturing city</h2>
          <p>
            Product origin is often mislisted in existing databases. On our app
            you can scan and verify product manufacturing city’s to help support
            transparency in the marketplace
          </p>
        </div>
        <div className="img">
          {width < 1200 ? (
            <img src={ScanPhone} alt="" />
          ) : (
            <img src={ScanPhoneDesktop} alt="" />
          )}
        </div>
      </div>
    </div>
  );
}
