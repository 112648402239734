import React from "react";
import { useRouter} from "./../util/router.js";
import IconBack from "../assets/images/arrow-left-white.svg";


export default function SingleProduct({
  imageUrl,
  name,
  manufacturingCountry,
  upc,
  amazonUrl = false,
  walmartUrl = false,
  company = "",
  description = false,
}) {
  const router = useRouter();



  const handleGlossier = () => {
    router.push(
      `/${router.query.category}/${router.query.brandId}/redirect=${true}`
    );
  };


  return (
    <>
      <section className="SingleProduct">
        <div className="SingleProduct__Container">
          <div className="Img">
            <img src={imageUrl} alt={name} />
          </div>
          <div className="Detail">
            <div className="Text">
              <h2>{name}</h2>
              <p>
                Brand: <span>{company}</span>
              </p>
              <p>
                Manufacturing Country: <span>{manufacturingCountry}</span>
              </p>
              <p>
                UPC: <span>{upc}</span>
              </p>
            </div>
            {description && (
              <div className="Text__Description">
                <ul>
                  <li>{description}</li>
                </ul>
              </div>
            )}

            <div className="Buttons">
              {amazonUrl && amazonUrl !== "" && (
                <a
                  href={amazonUrl}
                  target="__blank"
          
                  onClick={()=> window.gtag_report_conversion(amazonUrl)}
                >
                  Shop on Amazon
                </a>
              )}
              {walmartUrl && walmartUrl !== "" && (
                <a
                  href={walmartUrl}
                  target="__blank"
                  onClick={()=>window.gtag_report_conversion(walmartUrl)}
                >
                  Shop on Walmart
                </a>
              )}
              <div className="SingleProduct__button">
                <button
                  className="SingleProduct__view-company"
                  onClick={handleGlossier}
                >
                  {`View ${company}`}
                  <img
                    src={IconBack}
                    alt="arrow"
                    className="SingleProduct__icon"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
