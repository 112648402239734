import React, { useCallback, useEffect, useState } from "react";
import { socialIcons } from "../data/socialIcons.js";
import LogoWhite from "../assets/images/new-footer/Logo.png";
import buttonApple from "../assets/images/footer/app-store.png";
import { Link } from "./../util/router.js";
import Block from "../components/Block";
import localAuth from "../util/local-auth.js";
import { useSize } from "../util/custom-hooks/browsersize";
import ProductImage from "../assets/images/register/frame.png";
import ProductImageMobile from "../assets/images/register/mobile.png";
import CloseIcon from "../assets/images/register/closebutton.png";
import BuyableIcon from "../assets/images/register/b.png";
import { Modal } from "react-responsive-modal";
import { useLocalStorage } from "./../util/custom-hooks/useLocalStorage";


export default function NewFooter({ appStoreUrl }) {
    const size = useSize();
    const [userLanding, setUserLanding] = useLocalStorage("landing", "yes"); 
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [error, setError] = useState(false);
    const toggleLoading = useCallback(
        () => setLoading((state) => !state),
        [setLoading]
      );
    
      const onToggleModal = useCallback(
        () => setOpenModal((state) => !state),
        [setOpenModal]
      );
      const onFinish = (e) => {
        e.preventDefault();
        saveUserEmail(e.target.email_registered.value);
        setUserLanding("true");
      };
    
      useEffect(() => {
        if (userLanding === "yes") onToggleModal();
      }, [onToggleModal, userLanding]);
    
    
      const saveUserEmail = useCallback(async (email) => {
        const errorMessage = "Error, please try later"
        const data = {
          email: email,
        };
        let response = [];
        try {
          response = await localAuth.saveEmail(data);
    
          if (response.success) {
            toggleLoading();
            setTimeout(() => {
              setOpenModal(false);
              toggleLoading();
            }, 2000);
          } else {
            setError(errorMessage);
          }
        } catch (error) {
          setError(errorMessage);
          return error;
        }
      }, [toggleLoading]);


  return (
<>
    <div className="Footer">
    <Block appearance={["gray"]}>
      <div className="Footer__Feedback">
        <h1> Want to stay updated ? </h1>{" "}
        <p> Sign up for our newsletter </p>{" "}
        <button onClick={onToggleModal}>
          Sign Up{" "}
        </button>{" "}
      </div>{" "}
    </Block>
    </div>
    <section className="NewFooter">
      <div className="NewFooter__Container">
        <div className="NewFooterLinks">
          <div className="Logo">
            <img src={LogoWhite} alt=""/>
            <p>Get the facts. Shop smart.</p>
          </div>
          <div className="Menu">
            <Link to="/shop" className="Menu__Link">Shop</Link>

            <Link to="/verified-brands" className="Menu__Link">Verified Brands</Link>
            <Link to="/industry-insights" className="Menu__Link">Industry Insights</Link>
            <Link to="/about" className="Menu__Link">About</Link>
            <a
             className="Menu__Link Menu__Link--underline"
              rel="noreferrer"
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSdW12k2iLM6vCJkMZ38yotjjAaLZyPaBkYVFwKuzBw8zMGPQw/viewform?usp=sf_link"
            >
              Recommend a Company
            </a>
            <a className="Menu__Link Menu__Link--underline" rel="noreferrer" target="_blank" href="https://docs.google.com/forms/d/1nzVxzHv_T8pevIcCa181KecbDzs_7pp3mg-_1PXo-Q0/viewform?edit_requested=true">
              Give us Feedback
            </a>
          </div>

          <div className="Social">
            <p> Follow Us </p>
            <div className="Icons">
              {" "}
              {socialIcons.map((el, i) => (
                <a href={el.url} target="_blank" rel="noreferrer" key={i}>
                  <img
                    src={el.iconImage}
                    alt={el.alt}
                    className="social-icons"
                  />
                </a>
              ))}{" "}
            </div>{" "}
            <a
              className="Store"
              href={appStoreUrl}
              target="_blank"
              rel="noreferrer"
            >
              <img src={buttonApple} alt="" />
            </a>
          </div>
        </div>

        <div className="NewFooterPrivacyTerms__Container">
          <p>
           {/* <span className="Links">
              <Link to="/" className="Link">
                Privacy Policy {" "}
              </Link>
              |
              <Link to="/" className="Link">
              {" "}  Terms of Use
              </Link>
            </span>
              */}
          </p>
          <p className="legal">© Copyright 2020. All Rights Reserved. Buyable.</p>
        </div>
      </div>
    </section>

    <Modal
        open={openModal}
        center
        onClose={() => {
          onToggleModal();
          setUserLanding("no");
        }}
        classNames={{
          overlay: "customOverlayRegister",
          modal: "customModalRegister",
        }}
        closeIcon={<img src={CloseIcon} alt="" />}
      >
        <div className="modalRegister">
          <div className="modalImage">
            {" "}
            {size.width >= 992 ? (
              <>
                <img src={ProductImage} alt="" className="product" />

                <img src={BuyableIcon} alt="" className="icon" />
              </>
            ) : (
              <img src={ProductImageMobile} alt="" className="product" />
            )}{" "}
          </div>{" "}
          <div className="modalText">
            <h1> Be the First to Know </h1>
            <h6>
              Register your email below to receive updates on our latest
              verified brands, new industry insights & more.{" "}
            </h6>{" "}
            <h3> +Win $50 </h3>{" "}
            <p>
              {" "}
              Plus a chance to win a $50 giftcard to your brand of choice!{" "}
            </p>
            <form onSubmit={onFinish}>
              <input
                type="email"
                id="email_registered"
                name="email_registered"
                required
                placeholder="Enter your email address"
              />
              <input
                type="submit"
                value={`${loading ? "Done!" : "Submit"}`}
                className={`email-submit-button--${
                  loading ? "green" : "black"
                }`}
              />{" "}
            </form>{" "}
            {error && <span>{error} </span>}{" "}
          </div>{" "}
        </div>{" "}
      </Modal>
    </>
  );
}
