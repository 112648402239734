import React from "react";

function HeroSection({
  title,
  subtitle,
  paragraphs,
  heroImg,
  altImg,
  icon, 
  buyableLogo,

}) {
  return (
 
      <section className="Hero">
        <div className="Hero__Section--Black">
          <div className="Hero__Container">
            <div className="Text__Container">

              <div><img src={icon} alt=""/><img src={buyableLogo} alt=""/></div>
           
           
              <h2>{title}</h2>
              <h3>{subtitle}</h3>

              <p>
                {paragraphs[0]}
                {paragraphs[1]}
              </p>
            
             {/* {size.width >= 1200 && (
                <>
                  <a
                    href="https://apps.apple.com/us/app/buyable-shop-smarter/id1541213172"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={buttonImg} alt={altImg} />
                  </a>
                  <p className="slogan">{slogan}</p>
                </>
             )}  */}
            </div>
            <div className="Img__Container">
              <img src={heroImg} alt={altImg} />
            </div>
          </div>
        </div>
        <div className="Hero__Section--Light">
          <div className="Hero__Container">
        
          </div>
        </div>
      </section>

  );
}

export default HeroSection;
