import React, { useState, useEffect, useCallback } from "react";
import HeroSection from "./../components/HeroSection";
import HeroImg from "../assets/images/hero.png";
import BrandRectangle from "../assets/images/Group.png";
import BrandRectangleMobile from "../assets/images/brand-mobile.png";
import { useRouter } from "./../util/router.js";
import ButtonImg from "../assets/images/AppStore.svg";
import CategoriesSection from "../components/CategoriesSection";
//import PostsSection from "./../components/PostsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import BrandSection from "./../components/BrandSection";
import AppStoreLogo from "../assets/images/new-home/App Store.svg";
import AppStoreSection from "./../components/AppStoreSection";
import { categories } from "../data/category.js";
import ShopHomeSection from "./../components/ShopHomeSection";
import Beauty from "../assets/images/new-home/beauty-small.png";
import Food from "../assets/images/new-home/drink.png";
import Medical from "../assets/images/new-home/medical-small-1.png";
import Arrow from "../assets/images/arrow-left-1.svg";
import { useSize } from "../util/custom-hooks/browsersize";
import Industry from "./../components/Industry";
import BIcon from "../assets/images/new-home/b.png";
import BuyableLogo from "../assets/images/new-home/BUYABLE-hero.png";
import "aos/dist/aos.css";

import localAuth from "../util/local-auth.js";


function IndexPage() {
  const size = useSize();
  const [allArticles, setAllArticles] = useState([]);
;
  const router = useRouter();

  const goSingleArticle = (name) => {
    router.push(`/industry-insights/post/${name}`);
  };
  const goInsightsPage = () => router.push(`/industry-insights`);
  const getImage = useCallback(async (id) => {
    let response = [];
    try {
      response = await localAuth.getArticleImage(id);

      if (response.success) {
        return response.data;
      }
    } catch (error) {
      return false;
    }
  }, []);


  const getArticles = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getAllArticles();

      if (response.success) {
        const articles = response.data.slice(0, 4);

        await articles.map(async (article) => {
          let singleArticle = article;
          singleArticle.image_article = await getImage(article.id);
          setAllArticles((prevArticles) => [...prevArticles, singleArticle]);
        });
      }
    } catch (error) {
      console.log({
        error,
      });
    }
  }, [getImage]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getArticles();
    }
    return () => {
      isMounted = false;
    };
  }, [getArticles]);

  const goShop = (route) =>
    route.length > 0
      ? router.push(`/verified-brands/${route}`)
      : router.push(`/verified-brands`);

  return (
    <>
      <HeroSection
        heroImg={HeroImg}
        buttonImg={ButtonImg}
        icon={BIcon}
        buyableLogo={BuyableLogo}
        altImg="Buyable"
        title="Buy with Confidence"
        subtitle="Community Driven + Expert-Verified Shopping"
        slogan={`${
          size.width < 1200 ? "The World's Most Accurate Product Database" : ""
        }`}
        anchorPath="/pricinghttps://apps.apple.com/us/app/buyable-shop-smarter/id1541213172"
        paragraphs={[
          "We’re creating the world’s most accurate company and product catalog to help individuals make better, more educated purchases. We provide company insights so you can select brands that align with the standards you care about.",
          " We’ve done the research for you so you can start making more informed, confident purchases today.",
        ]}
        size={size}
      />{" "}
      <ShopHomeSection
        size={size}
        arrow={Arrow}
        title="Shop by Brand"
        paragraph="Informed Shopping"
        buttonText="Shop Now"
        beauty={Beauty}
        food={Food}
        goShop={goShop}
        medical={Medical}
      />{" "}
      <CategoriesSection
        categories={categories}
        title="Shop by Category"
        paragraph="Support Transparency"
      />
      <Industry
        title="Make Smarter Purchases"
        subtitle="Our experts provide insights, so you can make more educated purchases"
        posts={size.width < 1200 ? allArticles : allArticles.slice(0, 3)}
        arrow={Arrow}
        goInsightsPage={goInsightsPage}
        goSingleArticle={goSingleArticle}
        size={size}
      />{" "}
      <TestimonialsSection />
      <BrandSection
        size={size}
        image={BrandRectangle}
        imageMobile={BrandRectangleMobile}
      />{" "}
      <AppStoreSection
        icon={AppStoreLogo}
        href="https://apps.apple.com/us/app/buyable-shop-smarter/id1541213172"
        heading="Get Involved."
        paragraph="Help make a difference in the transparency of products."
      />
    
    </>
  );
}

export default IndexPage;
