
import SkinCareImg from "../assets/images/beauty-new.png";
import FoodImg from "../assets/images/food-new.png";
import PetCareImg from "../assets/images/pet-care.png";

 const categories = [
 
  {
    categoryImage: SkinCareImg,
    category: "Beauty & Skincare",
    alt: "Category Skin Care",
    route:"/beauty"
  },
  {
    categoryImage: FoodImg,
    category: "Food & Beverage",
    alt: "Category Food",
    route:"/food"
  },
  {
    categoryImage: PetCareImg,
    category: "Pet Care",
    alt: "Category Food",
    route:"/pet-care"
  },
];

export { categories };
