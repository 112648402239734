import React from "react";
import User from "../assets/images/User.png";
import QuotationMark1Dskt from "../assets/images/Quoted.svg";
import Fade from "react-reveal/Fade";
function TestimonialsSection() {
  const items = [
    {
      avatar: User,
      name: "Sharon",
      testimonial:
        " I want to support locally-made products but don’t know where to start. Buyable has made this a lot easier for me and my family!",
      description: "Mom, accountant, household shopper.",
    },
  ];

  return (
     <Fade delay={100}>
    <section className="Testimonials">
      <div className="Testimonials__Container">
        {items.map((item, index) => (
          <div key={index}>
            <div className="Quote">
              <img
                src={QuotationMark1Dskt}
                alt="Testimonial"
                className="Quote--mark Quote--mark--1"
              />
                <img
                src={QuotationMark1Dskt}
                alt="Testimonial"
                className="Quote--mark Quote--mark--Desktop"
              />
              <p className="Quote--text"> {item.testimonial}</p>

            
            </div>
            <div className="Description">
              <img src={item.avatar} alt={item.name} />
              <p className="Description--name">{item.name}</p>
              <p className="Description--text">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
    </Fade>
  );
}

export default TestimonialsSection;
