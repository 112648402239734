import React from "react";

export default function BrandSection({ image, alt = "", size, imageMobile }) {
  return (
    <img
      src={size.width > 440 ? image : imageMobile}
      alt={alt}
      className="BrandRectangle"
    />
  );
}
