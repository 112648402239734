import React from "react";
import { Link } from "./../util/router.js";
import Fade from "react-reveal/Fade";

function CategoriesSection({ categories, title, paragraph }) {
  return (
    <Fade delay={200}>
      <section className="Categories" data-aos="fade-in">
        <div className="Categories__Container">
          <p>{paragraph}</p>
          <h2>{title}</h2>

          <div className="Category">
            {categories.map((e, i) => (
              <Link to={`/verified-brands${e.route}`} className="Category__Link" index={i}>
                <div className="Category__Container" key={i}>
                  <img
                    src={e.categoryImage}
                    alt={e.alt}
                    className="Category__Image"
                  />
                  <h3 className="Category__Title">{e.category}</h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </Fade>
  );
}

export default CategoriesSection;
