import React, { useState, useEffect, useCallback } from "react";
import Block from "../components/Block";
import TopStories from "../components/TopStories";
import Insights from "../components/Insights";
import { all } from "../data/industry-insights/all";

import { useSize } from "../util/custom-hooks/browsersize";
import { useRouter } from "../util/router";
import localAuth from "../util/local-auth.js";
import Loader from "../components/Loader";

function IndustryInsightsPage() {
  const size = useSize();
  const router = useRouter();

  const [lastArticleHtml, setLastArticleHtml] = useState({});
  const [lastArticleData, setLastArticleData] = useState({});
  const [firstArticles, setFirstArticles] = useState([]);

  const [lastArticle, setLastArticle] = useState({});
  const [loading, setLoading] = useState(true);
  const goSingleArticle = (name) => {
    router.push(`/industry-insights/post/${name}`);
  };

  const getImage = useCallback(async (id) => {
    let response = [];
    try {
      response = await localAuth.getArticleImage(id);

      if (response.success) {
        return response.data;
      }
    } catch (error) {
      return false;
    }
  }, []);
  const getArticles = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getAllArticles();

      if (response.success) {
        let lastItem = response.data[0];
        lastItem.image_article = await getImage(response.data[0].id);

        const content = lastItem.content.split("</p>")[0] + "</p>";
        const initialItems = response.data.slice(1);
        await initialItems.map(async (article) => {
          let singleArticle = article;
          singleArticle.image_article = await getImage(article.id);
          setFirstArticles((prevArticles) => [...prevArticles, singleArticle]);
        });

        setLastArticle(lastItem);
        setLastArticleHtml(content);
        setLastArticleData(JSON.parse(lastItem.data));

        setLoading(false);
      }
    } catch (error) {
      console.log({
        error,
      });
    }
  }, [getImage]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getArticles();
    }
    return () => {
      isMounted = false;
    };
  }, [getArticles]);

  if (loading) {
    return <Loader appearance="full" timeout={500} />;
  }

  return (
    <>
      <Block appearance={["small"]}>
        <TopStories
          content={lastArticleHtml}
          lastArticle={lastArticle}
          size={size}
          lastArticleData={lastArticleData}
          goSingleArticle={goSingleArticle}
          getImage={getImage}
        />{" "}
      </Block>
      <Block>
        <Insights
          goSingleArticle={goSingleArticle}
          size={size}
          insights={all}
          articles={firstArticles}
        />{" "}
      </Block>{" "}
    </>
  );
}

export default IndustryInsightsPage;
