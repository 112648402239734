import React from "react";
import moment from "moment";
import Share from "../assets/images/industry-insights-page/share-1.png";

export default function Insights({ size, goSingleArticle, articles }) {

  return (
    <section className="Insights">
      <div className="Insights__Container">
        {articles &&
          articles.map((insight) => {
            let parsedData = JSON.parse(insight.data);

            return (
              <div
                className="Insight__Container"
                key={insight.id}
                onClick={() => goSingleArticle(insight.id)}
              >
                <div className="Img__Container">
                  <img
                    className="Img-post"
                    src={insight.image_article}
                    alt={parsedData.tag}
                  />

                  <div className="Text__Container">
                    <p className="Category">
                      <span>{parsedData.tag}</span>
                      {size.width >= 1200 && (
                        <span>{moment.unix(insight.date).format("ll")}</span>
                      )}
                    </p>
                    <p>{parsedData.title}</p>

                    {size.width < 1200 && (
                      <div className="Date__Row">
                        <span>{moment.unix(insight.date).format("ll")}</span>
                        <img src={Share} alt="share" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
}
