import React from "react";
import bbbMobile from "../assets/images/rates-page/bbb-mobile.png";

export default function GlobalRatings({ globalRatings, size }) {
  return (
    <section className="GlobalRatings">
      <div className="GlobalRatings__Container">
        <h2>Global Certifications</h2>
        <div className="Ratings__Container">
          {globalRatings &&
            globalRatings.map((rating, i) => (
              <>
                {size.width <= 1199 && <div className="Dot">{i + 1}</div>}

                <div className="Rating">
                  <div className={`Img__Container Img__Container--${i}`}>
                    {i === 0 && size.width <= 1199 ? (
                      <img src={bbbMobile} alt={rating.name} />
                    ) : (
                      <img src={rating.ratingImage} alt={rating.name} />
                    )}
                  </div>
                  <div className="Text__Container">
                    <h4>{rating.name}</h4>
                    <p>{rating.detail}</p>
                  </div>
                </div>
              </>
            ))}
        </div>
      </div>
    </section>
  );
}
