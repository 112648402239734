import React from 'react'


export default function Paragraph({text, appearance=[]}) {
    const Classes = appearance
    .map((mod) => `Paragraph--${mod}`)
    .join(" ");
    return (    
        <p className={`Paragraph ${Classes}`}>
            {text}
        </p>
     
    )
}
