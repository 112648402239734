import React from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import Paragraph from "./Paragraph";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "black",
    padding: "41.45px 32px 48.71px",
    "@media (min-width: 992px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  paper: {
    borderRadius: "150px",

    "@media (min-width: 992px)": {
      width: "561px",
    },
    "@media (max-width: 991px)": {
      margin: "auto",
      maxWidth: "329.62px",
    },
  },
  inputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 50px",
    padding: "4.5px 4.5px 4.5px 1rem",
  },
  label: {
    display: "block",
  },
  input: {
    width: "100%",
    fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    color: " #000000",
    "@media (min-width: 992px)": {},
  },
  listbox: {
    width: "272px",
    borderRadius: "10px",
    margin: 0,
    padding: 10,
    zIndex: 1,
    position: "absolute",

    listStyle: "none",
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    maxHeight: 200,
    fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    fontWeight: "normal",
    lineHeight: "1.5em",
    color: "#919191",
    fontSize: "14px",
    border: "1px solid rgba(0,0,0,.25)",
    '& li[data-focus="true"]': {
      backgroundColor: "#4a8df6",
      color: "white",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: "#2977f5",
      color: "white",
    },
    "@media (min-width: 992px)": {
      width: "561px",
    },
  },
  iconButton: {
    backgroundColor: "black",

    "&:hover": {
      backgroundColor: "black",
      opacity: ".9",
    },
  },
}));

export default function UseAutocomplete({
  slogan,
  valueRef,
  sendValue,
  getProducts,
  products,
  setValue,
  value
}) {
  const classes = useStyles();

  


  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: "use-autocomplete",
    freeSolo: true,
    options: products.length > 0 ? products : [],
    getOptionLabel: (option) => (option.name ? option.name : value),
  });

  return (
    <section className={classes.root}>
      <Paragraph appearance={["auto-complete"]} text={slogan} />
      <Paper
        component="form"
        className={classes.paper}
        onSubmit={(e) => {
        
          sendValue(e);
        }}
      >
        <div
          {...getRootProps()}
          className={classes.inputContainer}
       
          onChange={(e) => {
            setValue(e.target.value);
            getProducts(e.target.value);
          }}
        >
          <InputBase
    
 
            inputRef={valueRef}
            className={classes.input}
            placeholder="Search any product..."
            inputProps={{ "aria-label": "search products" }}
            {...getInputProps()}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          
          >
            <SearchIcon
              className={classes.searchIcon}
              style={{ color: "white" }}
            />
          </IconButton>
        </div>
        {groupedOptions.length > 0 ? (
          <ul className={classes.listbox} {...getListboxProps()}>
            {groupedOptions.map((option, index) => (
              <>
                <li {...getOptionProps({ option, index })}>{option.name}</li>
              </>
            ))}
          </ul>
        ) : null}
      </Paper>
    </section>
  );
}
