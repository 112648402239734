import React from "react";
import LogoDesktop from "../assets/images/about-page/desktop/BUYABLE(4).png";
import logoBackground from "../assets/images/about-page/mobile/Group332(3).png";
import LogoMobile from "../assets/images/about-page/mobile/Group400.png";
import Img1 from "../assets/images/about-page/mobile/p.png";
import Img2 from "../assets/images/about-page/mobile/s.png";
import Img1Desktop from "../assets/images/about-page/desktop/p.png";
import Img2Desktop from "../assets/images/about-page/desktop/s.png";
import Stars from "../assets/images/about-page/mobile/stars.png";
import StarsDesktop from "../assets/images/about-page/mobile/stars.png";
import GetFacts from "../assets/images/about-page/mobile/SMART.png";
import GetFactsDesktop from "../assets/images/about-page/desktop/SMART.png";
import ImgCircle from "../assets/images/about-page/desktop/circle.png";

export default function AboutHero({ width }) {
  return (
      <div className="about-hero">
    <div className="about-hero__container">
      <div className="logos">
        {width >= 1200 ? (
          <div className="row">
          <img src={ImgCircle} alt="" />
          <img src={LogoDesktop} alt="" />
          </div>
        ) : (
          <div className="centered">
            <img src={LogoMobile} alt="" /> <img src={logoBackground} alt="" />
          </div>
        )}
      </div>
      <div className="grid__container">
        <div className="grid__container--images">
          {width < 1200 ? (
            <>
              <img src={Img1} alt="" />
              <img src={Img2} alt="" />
              <img src={Stars} alt="" />
            </>
          ) : (
            <>
              <img src={Img1Desktop} alt="" />
              <img src={Img2Desktop} alt="" />
              <img src={StarsDesktop} alt="" />
            </>
          )}
        </div>
        <div className="grid__container--text">
          <h2>Our Mission</h2>
          <p>
            At Buyable, our mission is to help people make informed buying
            decisions. We strive to build an accurate, transparent product
            catalog, providing you with company and product insights.
          </p>

          <p>
            We are teaming up with consumers like you, who care about where and
            how products are made, to crowdsource and bring transparency to
            everyday products.
          </p>

          {width < 1200 ? (
            <img src={GetFacts} alt="" />
          ) : (
            <img src={GetFactsDesktop} alt="" />
          )}
        </div>
      </div>
     
    </div>
      </div>
   
  );
}
