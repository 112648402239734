import React from "react";
import Half from "../assets/images/about-page/mobile/half.png";
import HalfDesktop from "../assets/images/about-page/desktop/half.png";
import LogoDesktop from "../assets/images/about-page/desktop/BUYABLE(4).png";
import LogoMobile from "../assets/images/about-page/mobile/Group400.png";
import Solution from "../assets/images/about-page/mobile/solution.png";
import SolutionDesktop from "../assets/images/about-page/desktop/solution.png";

export default function AboutSolution({ width }) {
  return (
    <div className="about-solution">
      <div className="about-solution__container">
        <div className="about-solution-img">
          {width < 1200 ? (
            <img src={Solution} alt="" />
          ) : (
            <img src={SolutionDesktop} alt="" />
          )}
        </div>
        <div className="about-grid">
          <div className="buyable-circle">
            {width < 1200 ? (
              <img src={Half} alt="" />
            ) : (
              <img src={HalfDesktop} alt="" />
            )}
          </div>
          <div className="buyable">
            {width < 1200 ? (
              <img src={LogoMobile} alt="" />
            ) : (
              <img src={LogoDesktop} alt="" />
            )}
            <h2>Community Driven + Transparent Shopping</h2>
            <p>
              Bringing transparency to consumers so you make more informed, more
              confident purchases starting <span>today</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
