import IconFacebook from "../assets/images/singleArticle/Facebook.png";
import IconLinkedin from  "../assets/images/singleArticle/Linkedin.png";
import IconEmail from "../assets/images/singleArticle/email-action-unread.png";
import IconTwitter from "../assets/images/singleArticle/Twitter.png";


 const socialIconsBlack = [
  {
    iconImage: IconFacebook,
    url: 'https://fb.me/buyableapp',
    alt:"Buyable Facebook",
  
  },
  {
    iconImage: IconTwitter,
    url: 'https://twitter.com/buyableapp',
    alt:"Buyable Twitter",
  },

  {
    iconImage: IconEmail,
    url: '#',
    alt:"Mail to",
  },
  {
    iconImage: IconLinkedin,
    url: 'https://www.linkedin.com/company/buyableapp',
    alt:"Buyable Linkedin",
  },

];



export { socialIconsBlack };