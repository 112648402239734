import React from 'react'

export default function Block({appearance=[], children}) {
    const Classes = appearance
    .map((mod) => `Block--${mod}`)
    .join(" ");
    return (
        <div className={`Block ${Classes}`}>
            {children}
        </div>
    )
}
