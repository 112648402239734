import React, { useState } from "react";
import Block from "../components/Block";
import GlobalRatings from "../components/GlobalRatings";
import CategoryRatings from "../components/CategoryRatings";
import { categoryRatingsBeauty } from "../data/ratings/category-ratings-beauty";
import { categoryRatingsFood } from "../data/ratings/category-ratings-food";
import { useSize } from "../util/custom-hooks/browsersize";
import { globalRatings } from "../data/ratings/global-ratings";

function RatingSystem() {
  const size = useSize();
  const [state, setState] = useState({
    category: "beauty",
    categoryName: "category",
  });

  const handleChange = (event) => {
    const categoryName = event.target.name;

    setState({
      ...state,
      [categoryName]: event.target.value,
    });
  };

  return (
    <div>
      <Block appearance={["container-slim"]}>
        <GlobalRatings globalRatings={globalRatings} size={size} />
      </Block>
      <Block appearance={["container-slim"]}>
        <CategoryRatings
          size={size}
          categoryRatings={
            state.category === "beauty"
              ? categoryRatingsBeauty
              : categoryRatingsFood
          }
          handleChange={handleChange}
          state={state}
        />
      </Block>
    </div>
  );
}

export default RatingSystem;
