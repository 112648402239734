import React, { useState, useEffect, useCallback } from "react";
import SectionCollections from "../components/SectionCollections";
import { useRouter } from "./../util/router.js";
import localAuth from "../util/local-auth.js";
import { Modal } from "react-responsive-modal";
import CloseIcon from "../assets/images/verified-page/close.png";
import AccordionFilter from "../components/AccordionFilter";
import "react-responsive-modal/styles.css";

function VerifiedBrands() {
  const [companies, setCompanies] = useState(false);
  const router = useRouter();
  const [isActive, setIsActive] = useState("");
  const [categorySelected, setCategorySelected] = useState("all");
  const [loading, setLoading] = useState(true);
  const [openFilters, setOpenFilters] = useState(false);
  const [stateSearch, setStateSearch] = useState({
    checkedFavorites: false,
    checkedBrands: false,
    checkedAll: false,
  });
  const [stateCategory, setStateCategory] = useState({
    checkedBeauty: false,
    checkedFood: false,
    checkedPetCare: false,
  });

  const onToggleModal = () => setOpenFilters(!openFilters);

  const handleChange = (event) => {
    const categoryName = event.target.value;
    setCategorySelected(categoryName);
    getCompanies(categoryName);
  };

  const handleChangeFavorites = (event) => {
    setStateSearch({
      checkedBrands: stateSearch.checkedBrands,
      checkedAll: false,
      [event.target.name]: event.target.checked,
    });
    setStateCategory({
      checkedBeauty: false,
      checkedFood: false,
      checkedPetCare: false,
    });
    getFavorites();
  };

  const getFavorites = useCallback(async (category) => {
    let response = [];
    try {
      response = await localAuth.getCompanies();

      if (response.success) {
        const filteredNoMedicationData = response.data.filter(
          (company) => company.approved
        );

        setCompanies(filteredNoMedicationData);
        setLoading(false);
      }
    } catch (error) {
    return error
    }
  }, []);

  const companiesFiltered = (object) => {
    let category;
    for (const property in object) {
      if (object[property]) {
        category = property.replace("checked", "");
      }
    }
    setCategorySelected(category === "PetCare" ? "Pet Care" : category);
    updateData(category === "PetCare" ? "Pet Care" : category);
  };

  const getCompanies = useCallback(async (category) => {
    const currentCategory =
      category === "pet-care" || category === "Pet-care"
        ? "Pet Care"
        : category.charAt(0).toUpperCase() + category.slice(1);

    if (currentCategory === "Beauty") {
      setStateCategory({
        ...stateCategory,
        checkedBeauty: true,
      });
    } else if (currentCategory === "Food") {
      setStateCategory({
        ...stateCategory,
        checkedFood: true,
      });
    } else if (currentCategory === "Pet Care") {
      setStateCategory({
        ...stateCategory,
        checkedPetCare: true,
      });
    }
    setLoading(true);
    let response = [];
    try {
      response = await localAuth.getCompanies();

      if (response.success) {


        const companiesByCategory = response.data.filter(
          (company) => company.categories[0]?.name === currentCategory
        );
        const filteredNoMedicationData = response.data.filter(
          (company) => company.categories[0]?.name !== "Medication"
        );

        currentCategory !== "All"
          ? setCompanies(companiesByCategory)
          : setCompanies(filteredNoMedicationData);
        setLoading(false);

      }
    } catch (error) {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = useCallback(async (category) => {
    let response = [];
    try {
      response = await localAuth.getCompanies();

      if (response.success) {
        const filteredNoMedicationData = response.data.filter(
          (company) => company.categories[0]?.name === category
        );

        setCompanies(filteredNoMedicationData);
        setLoading(false);
      }
    } catch (error) {
      return error
    }
  }, []);

  const handleChangeSearch = (event) => {
    setStateSearch({
      ...stateSearch,
      [event.target.name]: event.target.checked,
    });
    getCompanies("all");
  };

  const handleChangeBeauty = (event) => {
    const categoryObject = {
      checkedFood: false,
      checkedPetCare: false,
      [event.target.name]: event.target.checked,
    };
    setStateSearch({
      checkedFavorites: false,
      checkedBrands: false,
      checkedAll: false,
    });
    setStateCategory(categoryObject);
    companiesFiltered(categoryObject);
  };

  const handleChangePet = (event) => {
    const categoryObject = {
      checkedFood: false,
      checkedBeauty: false,
      [event.target.name]: event.target.checked,
    };
    setStateSearch({
      checkedFavorites: false,
      checkedBrands: false,
      checkedAll: false,
    });
    setStateCategory(categoryObject);
    companiesFiltered(categoryObject);
  };

  const clearFilters = (search = false) => {
    setStateCategory({
      checkedBeauty: false,
      checkedFood: false,
      checkedPetCare: false,
    });
    setStateSearch({
      checkedFavorites: false,
      checkedBrands: false,
      checkedAll: false,
    });
  };
  const handleChangeFood = (event) => {
    const categoryObject = {
      checkedBeauty: false,
      checkedPetCare: false,
      [event.target.name]: event.target.checked,
    };
    setStateSearch({
      checkedFavorites: false,
      checkedBrands: false,
      checkedAll: false,
    });
    setStateCategory(categoryObject);
    companiesFiltered(categoryObject);
  };
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let active = router.query.category
        ? router.query.category.charAt(0).toUpperCase() +
          router.query.category.slice(1)
        : "all";
      if (router.query.category) {
        setCategorySelected(router.query.category);
      }
      setIsActive(active);
      getCompanies(active);
    }
    return () => {
      isMounted = false;
    };
  }, [getCompanies, router]);

  return (
    <div className="VerifiedBrands__Grid">
      <AccordionFilter
        verifiedBrands
        handleChangeFavorites={handleChangeFavorites}
        clearCheckbox={clearFilters}
        stateSearch={stateSearch}
        stateCategory={stateCategory}
        handleChangePet={handleChangePet}
        handleChangeFood={handleChangeFood}
        handleChangeBeauty={handleChangeBeauty}
        handleChangeVerified={handleChangeSearch}
      />
      <SectionCollections
        onToggleModal={onToggleModal}
        route={isActive}
        dataCollection={companies}
        categoryName={categorySelected}
        handleChange={handleChange}
        router={router}
        loading={loading}
        defaultValue={categorySelected}
      />

      <Modal
        open={openFilters}
        onClose={onToggleModal}
        closeIcon={<img src={CloseIcon} alt="" />}
      >
        <AccordionFilter
          verifiedBrands
          handleChangeFavorites={handleChangeFavorites}
          clearCheckbox={clearFilters}
          stateSearch={stateSearch}
          stateCategory={stateCategory}
          handleChangePet={handleChangePet}
          handleChangeFood={handleChangeFood}
          handleChangeBeauty={handleChangeBeauty}
          handleChangeVerified={handleChangeSearch}
          modal={true}
        />
      </Modal>
    </div>
  );
}
export default VerifiedBrands;
