import Img1 from "../../assets/images/industry-insights-page/beauty-new.png";

import ImgBaby from "../../assets/images/industry-insights-page/baby-new.png";


const all = [


  { 
    
    postImage: ImgBaby,
    postImageMobile: ImgBaby,
    date: "Feb 02, 2021",
    category: "BABY FOOD",
    categoryMobile:"Baby Food",
    title: "Baby food brands that need to be cancelled",
    titleMobile:"Baby food brands that need to be cancelled"
   
  },
  { 
    
    postImage: Img1,
    postImageMobile: Img1,
    date: "Feb 02, 2021",
    category: "Skincare",
    categoryMobile:"Skincare",
    title:"How this skincare brand changed my skin",
    titleMobile:"How this skincare brand changed my skin"
   
   
  }
 
  
];

export { all };
