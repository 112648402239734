import React from "react";
import ShareImg from "../assets/images/industry-insights-page/share-1.png";
import Arrow from "../assets/images/arrow-left-1.svg";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      border: "1px solid #919191",
      borderRadius: "5px",
      width: "149px",
      padding: "0 9.5px",
      fontSize: "14px",
      fontFamily: "Avenir",
      color: "#919191",
      height: "41.1px",
    },

    "& .MuiInput-underline::before": {
      border: "none",
    },
    "& .MuiInput-underline::after": {
      border: "none",
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "none",
    },
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function TopStories({
  lastArticle,
  size,
  goSingleArticle,
  lastArticleData,
  content,
}) {
  const classes = useStyles();

  return (
    <section className="TopStories">
      <div className="TopStories__Container">
        <div className="TopStories__Title">
          <h2>Featured</h2>
          <FormControl variant="filled" className={classes.formControl}>
            <NativeSelect
              className={classes.selectEmpty}
              name="options"
              inputProps={{
                name: "options",
                id: "filled-age-native-simple",
              }}
            >
              <option value="" placeholder>
                Filter
              </option>
              <option value={"list-a-z"}>List A-Z</option>
              <option value={"featured"}>Featured</option>
              <option value={"new"}>New</option>
            </NativeSelect>
          </FormControl>
        </div>

        <>
          <div
            className="TopStory__Container"
            index={lastArticle.id}
            onClick={() =>
              goSingleArticle(
                lastArticle.id
              )
            }
          >
            <div className="Img__Container">
              {
               lastArticle.image_article && (
                <img src={lastArticle.image_article} alt={" "} />
               )
              }
           
            </div>
            <div className="Text__Container">
              {size.width > 991 ? (
                <div className="heading">
                  {" "}
                  <h6>{lastArticleData.tag}</h6>{" "}
                  <span>
                    {moment.unix(lastArticle.date).format("ll")}
                  </span>
                </div>
              ) : (
                <h6>{lastArticleData.tag}</h6>
              )}
              <h3>{lastArticleData.title}</h3>
              <p>{lastArticleData.article_description}</p>
              <div className="Date__Row">
                {size.width < 992 && (
                  <>
                    <span>  {moment.unix(lastArticle.date).format("ll")}</span>
                    <img src={ShareImg} alt="share" />{" "}
                  </>
                )}
              </div>
            </div>
          </div>
          {size.width < 992 && (
            <button>
              Read More <img src={Arrow} alt="share" />
            </button>
          )}
        </>
      </div>
    </section>
  );
}
