import React from "react";
import Fade from "react-reveal/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Loader from "../components/Loader";
import VerifiedLogo from "../assets/images/verified-page/Component 1.png";
import { Link } from "../util/router.js";

const useStyles = makeStyles((theme) => ({
  buttonControl: {
    margin: " 0",
    background: "transparent",
    minWidth: 105,
    height: "38.7px",
    borderRadius: "30px",
    border: "1px solid #000000",
    textTransform: "capitalize",
    "@media (min-width: 992px)": {
      display: "none",
    },
  },
}));

export default function SectionCollections({
  dataCollection,
  loading,
  onToggleModal,
  title = "Shop"

}) {

  const classes = useStyles();
  return (
    <Fade delay={100}>
      <section className="">
        <div className="Collection__Filter">
          <h2>
            {title}
         
          </h2>

          <Button className={classes.buttonControl} onClick={onToggleModal}>
            Sort & Filter
          </Button>
        </div>
        {loading ? (
          <Loader />
        ) : (
        
          <div className="SectionProducts">
            {dataCollection &&
              dataCollection.map(({ name, approved, id, companyName, companyId, categoryId }) => (
                <Link to={`/${categoryId ===1 ?"food": categoryId===2 ?"beauty-and-skincare": categoryId===9?"pet-care":"shop" }/${companyId}/redirect=${true}/product/${id}`} style={{textDecoration:"none"}}>
                  <div
                    className={`Card__ProductContainer Card__ProductContainer--verified Card__ProductContainer--approved`}
                  >
                    <div className="Product">
                      <div className="Approved">Buyable Favorite</div>

                      <div className="Verified">
                        <img src={VerifiedLogo} alt="" />
                      </div>
                      <div className="ProductImage">
                        <img src={`${process.env.REACT_APP_SWAGGER_URL}/product/image/${id}`} alt={name} />
                      </div>
                    </div>

                    <h4>{name}</h4>
                    <h3>{companyName}</h3>
                  </div>
                </Link>
              ))}
          </div>
        )}
      </section>
    </Fade>
  );
}
