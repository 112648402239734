import React, { useCallback, useState, useEffect } from "react";
import BrandHeader from "../components/BrandHeader";
import RatingsSection from "../components/RatingsSection";
import ProductsSection from "../components/ProductsSection";
import { products } from "../data/brand/products-food";
import { useRouter } from "./../util/router.js";
import localAuth from "../util/local-auth.js";
import { useSize } from "../util/custom-hooks/browsersize";
import Loader from "../components/Loader";
import { useHistory } from "react-router-dom";
import CoverImageHeader from "../components/CoverImageHeader";
import BreadCrumbIcon from "../assets/images/breadcrumb/Vector.png";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1199px",
    margin: "auto",
    padding: "1.5rem 2rem",
  },
  prevLink: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "11px",
    textDecorationLine: "underline",
    color: "#000000",
    cursor: "pointer",
  },
  current: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "10px",
    lineHeight: "11px",
    color: "#000000",
    "&:hover": {
      textDecorationLine: "none",
    },
  },
  separator: {},
}));
export default function FoodBrand() {
  const router = useRouter();
  let history = useHistory();
  const [company, setCompany] = useState({});
  const [companyGlobalRatings, setCompanyGlobalRatings] = useState(false);
  const size = useSize();
  const [coverImage, setCoverImage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [amazonProducts, setAmazonProducts] = useState([]);
  const redirect = router.query.redirect.split("=")[1];
  const [fromProductPage, setFromProductPage] = useState(false);
  const classes = useStyles();
  const goProductPage = (path) => {
    router.push(path);
  };

  const goBack = () => {
    history.goBack();
  };

  const getCoverImage = useCallback(async () => {
    try {
      const response = await localAuth.getApprovedContent(router.query.brand);
      if (response.success) {
        setCoverImage(response.data);
      }
    } catch (error) {
      setLoading(false);

      return error;
    }
  }, [router]);
  const getCompany = useCallback(async () => {
    try {
      const response = await localAuth.getCompany(router.query.brand);
      if (response.success) {
        setLoading(false);
        setCompany(response.data);
      }
    } catch (error) {
      setLoading(false);

      return error;
    }
  }, [router]);

  const getAmazonProducts = useCallback(async () => {
    try {
      const response = await localAuth.getAmazonProducts(router.query.brand);

      if (response.success) {
        setAmazonProducts(response.data);
      }
    } catch (error) {
      return error;
    }
  }, [router]);
  const getCompanyGlobalRatings = useCallback(async () => {
    try {
      const response = await localAuth.getCompanyGlobalRatings(
        router.query.brand
      );

      if (response.success) {
        setCompanyGlobalRatings(response.data);
      }
    } catch (error) {
      console.log("Error getting global rating");

      return error;
    }
  }, [router]);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCompany();
      setFromProductPage(redirect === "true");
      getCompanyGlobalRatings();
      getAmazonProducts();
      getCoverImage();
    }
    return () => {
      isMounted = false;
    };
  }, [
    redirect,
    getCompany,
    getCompanyGlobalRatings,
    getAmazonProducts,
    getCoverImage,
  ]);

  if (loading) {
    return <Loader appearance="full" timeout={500} />;
  }
  return (
    <>
      {Object.keys(company).length !== 0 && company.constructor !== {} && (
        <>
          {coverImage && <CoverImageHeader coverImage={coverImage} />}

          <div className={classes.root}>
            <Breadcrumbs
              separator={
                <img
                  src={BreadCrumbIcon}
                  alt=""
                  className={classes.separator}
                />
              }
              aria-label="breadcrumb"
            >
              <Link
                color="inherit"
                href={`${fromProductPage ? "/shop" : "/verified-brands"}`}
                className={classes.prevLink}
              >
                {fromProductPage ? "Shop" : "Verified Brands"}
              </Link>
              {fromProductPage && (
                <span onClick={goBack} className={classes.prevLink}>
                  Back to product
                </span>
              )}

              <Link className={classes.current}>{company.name}</Link>
            </Breadcrumbs>
          </div>
          <BrandHeader
            prevRoute="/beauty-and-skincare"
            name={company.name}
            country="United States"
            location={company.headquarter}
            content={company.description}
            amazonUrl={company.amazonUrl}
            size={size}
            walmartUrl={company.walmartUrl}
            approved={company.approved}
            imageId={company.id}
          />

          <RatingsSection
            type="food"
            size={size}
            company={company}
            companyGlobalRatings={companyGlobalRatings}
          />
          <ProductsSection
            size={size}
            amazonProducts={amazonProducts}
            products={products}
            goProductPage={goProductPage}
            pathname={router.location.pathname}
            type="food"
            company={company.name}
          />
        </>
      )}
    </>
  );
}
