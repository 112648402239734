import React from "react";
import IconLocation from "../assets/images/brand-page/location.svg";

export default function BrandHeader({
  amazonUrl,
  walmartUrl,
  imgBrand,
  location,
  country,
  content,
  factoryLocation = false,
  size,
  name,
  imageId
}) {

  return (
    <section className="BrandHeader">
      <div className="BrandHeader__Container">
        <div className="BrandDetail">
          <div className="BrandDetail__Container">
            <div className="FirstSection">
              <div className="BrandImg__Container">
                <img src={`${process.env.REACT_APP_SWAGGER_URL}/company/image/${imageId}`} alt="brand" />
              </div>
             {size.width <992 && <h2 className="Company">{name}</h2>}
              <div className="BrandText__Container">
                <div>
                  <p>Headquarters:</p>
                  <p>
                    <img src={IconLocation} alt="Icon Location" />
                    {location}
                  </p>
                </div>
                <div>
                  <p>Manufacturing Country:</p>
                  <p>{country}</p>
                </div>
                {factoryLocation && (
                  <div>
                    <p>Factory Location:</p>
                    <p>{factoryLocation}</p>
                  </div>
                )}
              </div>
              {size.width < 992 && (
                <div className="ButtonSection">
            
                    <a href={"#products"} rel="noopener noreferrer">
                      Shop on Walmart
                    </a>
               

                  <a href={"#products"} rel="noopener noreferrer">
                    Shop on Amazon
                  </a>
                </div>
              )}
            </div>
            <div className="SecondSection">
            
                <a href={"#products"} rel="noopener noreferrer">
                  Shop on Walmart
                </a>
             
                <a href={"#products"} rel="noopener noreferrer">
                  Shop on Amazon
                </a>
            
            </div>
          </div>
          <p className="BrandContent">{content}</p>
        </div>
      </div>
    </section>
  );
}
