import React, { useState, useEffect, useRef } from "react";
import Section from "./Section";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "./../util/router.js";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  shadows: ["none"],
  logo: {
    height: 28,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
  button: {
    color: "white",
  },
  item: {
    minWidth: "4px",
    border: "none",
    color: "black",
    fontFamily: "Helvetica Neue",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    margin: "0 25.2px",
    letterSpacing: "0.1em",
    padding: "0 0 2px 0",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  place: {
    position: "relative",
    left: "29px",
    top: "10px",
  },
  dropdown: {
    border: "none",
    color: "black",
    fontFamily: "Helvetica Neue",
    fontWeight: "normal",
    padding: "8px 16px",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    "&:hover, &:active": {
      backgroundColor: "white",
    },
  },
  itemMobile:{
    color:'black',
    fontSize:'18px',
    fontWeight:'bold',
    fontFamily:'Avenir'
  }
}));

function Navbar(props) {
  const location = useLocation();

  const classes = useStyles();
  const [isActive, setIsActive] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleChange = () => {
    let active = location.pathname;

    setIsActive(active);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const logo = props.logo;
  const prevOpen = React.useRef(open);
  useEffect(() => {
    setIsActive(location.pathname);
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [location, open]);

  return (
    <Section className="Navbar" size="auto">
      <AppBar position="static" color="transparent" elevation={0}>
        <div className="Navbar__Container">
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className="Navbar__Logo" />
            </Link>
            <div className={classes.spacer} />
            <Hidden mdUp={true} implementation="css">
              <button
                className={`hamburger hamburger--spin hamburguer--white ${
                  drawerOpen ? "is-active" : ""
                }`}
                type="button"
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                <span className="hamburger-box">
                  <span
                    className="hamburger-inner"
                    style={{ backgroundColor: "black" }}
                  ></span>
                </span>
              </button>
            </Hidden>
            <Hidden
              smDown={true}
              implementation="css"
              className="Navbar__Items-container"
            >
              <Button
                disableRipple
                disableFocusRipple
                disableElevation
                onClick={handleChange}
                className={`${classes.item} ${
                  isActive.includes("/shop") ? `Navbar__Item--Active` : ""
                }`}
                component={Link}
                to="/shop"
              >
                Shop
              </Button>
              <Button
                disableRipple
                disableFocusRipple
                disableElevation
                onClick={handleChange}
                className={`${classes.item} ${
                  isActive.includes("/verified") ? `Navbar__Item--Active` : ""
                }`}
                component={Link}
                to="/verified-brands"
              >
                Verified Brands
              </Button>
              <Button
                className={`${classes.item} ${
                  isActive.includes("/industry-insights")
                    ? `Navbar__Item--Active`
                    : ""
                }`}
                component={Link}
                disableRipple
                disableFocusRipple
                disableElevation
                onClick={handleChange}
                to="/industry-insights"
              >
                Insights
              </Button>
              <Button
                component={Link}
                disableRipple
                disableFocusRipple
                disableElevation
                className={classes.item}
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                About
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    className={`${classes.place}`}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                            disableRipple
                            disableFocusRipple
                            disableElevation
                            onClick={handleClose}
                            component={Link}
                            to="/about"
                            className={`${classes.dropdown} ${
                              isActive === "/about" ? `Dropdown--Active` : ""
                            }`}
                          >
                            About Us
                          </MenuItem>
                          <MenuItem
                            disableRipple
                            disableFocusRipple
                            disableElevation
                            onClick={handleClose}
                            component={Link}
                            to="/certifications"
                            className={`${classes.dropdown} ${
                              isActive === "/certifications"
                                ? `Dropdown--Active`
                                : ""
                            }`}
                          >
                            Certifications
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Hidden>
          </Toolbar>
        </div>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >
          <button
            className={`hamburger hamburger--spin ${
              drawerOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => {
              setDrawerOpen(true);
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>

          <ListItem button={true} component={Link} to="/shop">
            <ListItemText className={classes.itemMobile}>Shop</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/verified-brands">
            <ListItemText className={classes.itemMobile}>Verified Brands</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/industry-insights">
            <ListItemText className={classes.itemMobile}>Insights</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/about">
            <ListItemText className={classes.itemMobile}>About Us</ListItemText>
          </ListItem>
          <ListItem button={true} component={Link} to="/certifications">
            <ListItemText className={classes.itemMobile}>Certifications</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </Section>
  );
}

export default Navbar;
