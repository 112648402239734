import Search from "../../assets/images/about-page/mobile/transparency.png";
import SearchDesktop from "../../assets/images/about-page/desktop/transparency.png";
import Info from "../../assets/images/about-page/mobile/Info.png";
import InfoDesktop from "../../assets/images/about-page/desktop/Info.png";
import Issues from "../../assets/images/about-page/mobile/enviromental.png";
import IssuesDesktop from "../../assets/images/about-page/desktop/enviromental.png";
import Economic from "../../assets/images/about-page/mobile/economic.png";
import EconomicDesktop from "../../assets/images/about-page/desktop/economic.png";
import Warning from "../../assets/images/about-page/mobile/warning.png";
import WarningDesktop from "../../assets/images/about-page/desktop/warning.png";

 const items = [
  { 
    icon:Search,
    iconDesktop: SearchDesktop,
    title: "No Transparency",
    text:"Understanding where products are manufactured",

 
  },

  { 
    icon:Warning,
    iconDesktop: WarningDesktop,
    title: "Dangerous Products",
    text:"Products are having a negative effect on consumers health",

 
  },
  { 
    icon:Economic,
    iconDesktop: EconomicDesktop,
    title: "Economic Impact",
    text:"Manufacturing jobs have been leaving the U.S.",

 
  },
  { 
    icon:Info,
    iconDesktop: InfoDesktop,
   
    title: "Misguided Database",
    text:"Most product databases do not include the manufacturing city."

 
  },
  { 
    icon:Issues,
    iconDesktop: IssuesDesktop,
    title: "Environmental Issues",
    text:"Companies using old methods of manufacturing "

 
  }
  

  

];

export { items };
