import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 120,
    "& .MuiNativeSelect-filled.MuiNativeSelect-filled": {
      border: "1px solid #919191",
      borderRadius: "5px",
      width: "149px",
      padding: "0 9.5px",
      fontSize: "14px",
      fontFamily: "Avenir",
      color: "#919191",
      height: "41.1px",
    },

    "& .MuiInput-underline::before": {
      border: "none",
    },
    "& .MuiInput-underline::after": {
      border: "none",
    },

    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "none",
    },
  },
}));

export default function CategoryRatings({
  categoryRatings,
  handleChange,
  state,
}) {
  const classes = useStyles();

  return (
    <section className="CategoryRatings">
      <div className="CategoryRatings__Container">
        <div className="CategoryRatings__Row">
          <h2>Category Certifications</h2>

          <FormControl variant="filled" className={classes.formControl}>
            <NativeSelect
              className={classes.selectEmpty}
              value={state.category}
              name="category"
              onChange={handleChange}
              inputProps={{
                name: "category",
                id: "filled-age-native-simple",
              }}
            >
              <option value="" disabled>
                Categories
              </option>

              <option value={"beauty"}>Beauty/Skincare</option>

              <option value={"food"}>Food/Beverage</option>
            </NativeSelect>
          </FormControl>
        </div>

        <div className="Category__Container">
          {categoryRatings &&
            categoryRatings.map((rating, i) => (
              <div className="Rating">
                <div className={`Img__Container Img__Container--${i}`}>
                  <img src={rating.ratingImage} alt={rating.name} />
                </div>
                <div className="Text__Container">
                  <h4>{rating.name}</h4>
                  <p>{rating.detail}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
