import Product1 from "../../assets/images/brand-page/food/product.png";



 const products = [
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
  {
    productImage: Product1,
    detail: "Vegan Cheesy Rice",

 
  },
];

export { products };
