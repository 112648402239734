
import Rating1 from "../../assets/images/rates-page/food/lactose-free.png";
import Rating2 from "../../assets/images/rates-page/food/lactose-free-plus.png";
import Rating3 from "../../assets/images/rates-page/food/gluten-free.png";
import Rating4 from "../../assets/images/rates-page/food/gluten-free-plus.png";
import Rating5 from "../../assets/images/rates-page/food/organic.png";
import Rating6 from "../../assets/images/rates-page/beauty/vegan.png";
import Rating7 from "../../assets/images/rates-page/beauty/veganv.png";
import Rating8 from "../../assets/images/rates-page/food/USDA.png";

const categoryRatingsFood = [
 
  {
    name: "Dairy-Free",
    ratingImage: Rating1,
    detail:
      "Products do not contain any dairy. It is normally made with plant or nut alternatives.",
  },
  {
    name: "Dairy-Free Products Available ",
    ratingImage: Rating2,
    detail:
      "The company offers dairy-free products.",
  },
  {
    name: "Gluten-Free",
    ratingImage: Rating3,
    detail:
      "Products exclude gluten, a mixture of proteins found in wheat, barley, rye & oats. ",
  },
  {
    name: "Gluten-Free Products Available",
    ratingImage: Rating4,
    detail:
      "The company offers gluten-free products.",
  },
  {
    name: "Organic",
    ratingImage: Rating5,
    detail:
      "Food is labeled organic when it contains at least 95% organic ingredients and NO use of potentially harmful chemicals or pesticide.",
  },
  {
    name: "Vegan",
    ratingImage: Rating6,
    detail:
      "Products that do not contain any ingredients that are derived from animals.",
  },
  {
    name: "Vegan Products Available",
    ratingImage: Rating7,
    detail:
      "A company offers vegan products.",
  },

  {
    name: "USDA Certified",
    ratingImage: Rating8,
    detail:
      "USDA certification verifies that farms or handling facilities comply with their regulations and allows producers to label their products organic.",
  },

  
  
  
];

export { categoryRatingsFood };
