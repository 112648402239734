import React, { useCallback, useState, useEffect } from "react";
import SingleProduct from "../components/SingleProduct";
//import NavigationButton from "../components/NavigationButton";
import localAuth from "../util/local-auth.js";
import { useRouter } from "./../util/router.js";
import ProductsSection from "../components/ProductsSection";
import { useSize } from "../util/custom-hooks/browsersize";
import BreadCrumbIcon from "../assets/images/breadcrumb/Vector.png";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import RatingsSection from "../components/RatingsSection";
import Loader from "../components/Loader";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1199px",
    margin: "auto",
    padding: "1.5rem 2rem",
  },
  prevLink: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "11px",
    textDecorationLine: "underline",
    color: "#000000",
  },
  current: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "10px",
    lineHeight: "11px",
    color: "#000000",
    "&:hover": {
      textDecorationLine: "none",
    },
  },
  separator: {},
}));

export default function ProductPage() {
  const size = useSize();
  const [product, setProduct] = useState({});
  const router = useRouter();
  const classes = useStyles();
  const [companyGlobalRatings, setCompanyGlobalRatings] = useState(false);
  const [company, setCompany] = useState(false);
  const [amazonProducts, setAmazonProducts] = useState([]);
  const goProductPage = (path) => {
    router.push(path);
  };




  const getCompany = useCallback(async () => {
    try {
      const response = await localAuth.getCompany(router.query.brandId);

      if (response.success) {
        setCompany(response.data);
      }
    } catch (error) {
      return error;
    }
  }, [router]);
  const getAmazonProducts = useCallback(async () => {
    try {
      const response = await localAuth.getAmazonProducts(router.query.brandId);

      if (response.success) {
        const filteredProducts = [
          ...response.data.filter(
            (product) => product.id !== parseInt(router.query.id)
          ),
        ];

        const randomProducts = filteredProducts.sort(() => 0.5 - Math.random());
        setAmazonProducts(randomProducts.slice(0, 3));
      }
    } catch (error) {
      return error;
    }
  }, [router]);

  const getProduct = useCallback(async () => {
    try {
      const response = await localAuth.getProduct(router.query.id);

      if (response.success) {
        setProduct(response.data);
      }
    } catch (error) {
      return error;
    }
  }, [router]);

  const getCompanyGlobalRatings = useCallback(async () => {
    try {
      const response = await localAuth.getCompanyGlobalRatings(
        router.query.brandId
      );
   

      if (response.success) {
        setCompanyGlobalRatings(response.data);
      }
    } catch (error) {
      return error;
    }
  }, [router]);


  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getProduct();
      getCompany();
      getAmazonProducts();
      getCompanyGlobalRatings();
    }
    return () => {
      isMounted = false;
    };
  }, [getProduct, getAmazonProducts, getCompany, getCompanyGlobalRatings]);

  return (
    <div
      style={{
        marginTop: `${router.query.category === "shop" ? "4rem" : "0"}`,
      }}
    >
      {company && product.name ? (
        <>
        <div className={classes.root}>
          <Breadcrumbs
            separator={
              <img src={BreadCrumbIcon} alt="" className={classes.separator} />
            }
            aria-label="breadcrumb"
          >
            <Link color="inherit" href="/shop" className={classes.prevLink}>
              Shop
            </Link>
            <Link
              href={`/${router.query.category}/${
                router.query.brandId
              }/redirect=${true}`}
              className={classes.prevLink}
            >
              {company.name}
            </Link>
            <Link className={classes.current}>{product.name}</Link>
          </Breadcrumbs>
        </div>
         <SingleProduct company={company.name} {...product} />{" "}
         
         <RatingsSection
               type={company?.categories[0]?.id === 2? "beauty":company?.categories[0]?.id === 1 ?  "food": "pet-care" }
               size={size}
               company={company}
               companyGlobalRatings={companyGlobalRatings}
               appearance="product"
            />
         
         <ProductsSection
           size={size}
           company={company.name}
           amazonProducts={amazonProducts}
           appearance="product-page"
           goProductPage={goProductPage}
           pathname={`/${router.query.category}/${
             router.query.brandId
           }/redirect=${true}`}
         />

         </>
      ): (< Loader/>)}

    </div>
  );
}
