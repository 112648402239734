import React from "react";
import ReactDom from "react-dom";
import App from "./pages/_app";
import ReactPixel from 'react-facebook-pixel';
import "react-responsive-modal/styles.css";
//import * as serviceWorker from "./serviceWorker";
import "./scss/main.scss";
import { hotjar } from 'react-hotjar';

ReactPixel.init('209526971093788');
hotjar.initialize(2608561, 6);

ReactDom.render(<App />, document.getElementById("root"));


