import React, { useState, useEffect, useCallback } from "react";
import { useSize } from "../util/custom-hooks/browsersize";
import { useRouter } from "./../util/router.js";
import { socialIconsBlack as socialIcons } from "../data/socialIconsBlack";
import Buyable from "../assets/images/singleArticle/BUYABLE.png";
import HelmetMetaData from "../components/Helmet";
import Share from "../assets/images/industry-insights-page/share-1.png";
import moment from "moment";
import Article from "../components/Article";
import Loader from "../components/Loader";
import { useLocation } from "react-router-dom";
import localAuth from "../util/local-auth.js";
import ReactHtmlParser from "react-html-parser";
export default function SingleArticle() {
  const size = useSize();
  const router = useRouter();
  let location = useLocation();
  let currentUrl = "https://buyable.us" + location.pathname;
  const [article, setArticle] = useState(false);
  const [articleDataParsed, setArticleDataParsed] = useState(false);
  const [imageArticle, setImageArticle] = useState(false);

  const getImage = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getArticleImage(router.query.id);

      if (response.success) {
        setImageArticle(response.data);
      }
    } catch (error) {
      return false;
    }
  }, [router]);

  const getArticle = useCallback(async () => {
    let response = [];
    try {
      response = await localAuth.getArticle(router.query.id);

      if (response.success) {
        setArticle(response.data);
        setArticleDataParsed(JSON.parse(response.data.data));
      }
    } catch (error) {
      console.log({ error });
    }
  }, [router]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getImage();
      getArticle();
    }
    return () => {
      isMounted = false;
    };
  }, [getArticle, getImage]);

  if (!imageArticle || !articleDataParsed) {
    return <Loader appearance="full" timeout={500} />;
  }
  return (
    <>
      <HelmetMetaData
        title={articleDataParsed.title}
        description={articleDataParsed.title}
      ></HelmetMetaData>

      {imageArticle && articleDataParsed && (
        <Article
          title={articleDataParsed.title}
          date={moment.unix(article.date).format("ll")}
          imageMobile={imageArticle}
          imageDesktop={imageArticle}
          size={size}
          buyable={Buyable}
          description={articleDataParsed.title}
          share={Share}
          socialIcons={socialIcons}
          currentUrl={currentUrl}
        >
          <>{ReactHtmlParser(article.content)}</>
        </Article>
      )}
    </>
  );
}
