import IconFacebook from "../assets/images/facebookicon.png";
import IconLinkedin from  "../assets/images/linkedinicon.png";
import IconInstagram from "../assets/images/instragramicon.png";
import IconTwitter from "../assets/images/twittericon.png";


 const socialIcons = [
  {
    iconImage: IconFacebook,
    url: 'https://fb.me/buyableapp',
    alt:"Buyable Facebook",
  
  },
  {
    iconImage: IconTwitter,
    url: 'https://twitter.com/buyableapp',
    alt:"Buyable Twitter",
  },

  {
    iconImage: IconInstagram,
    url: 'https://www.instagram.com/buyableapp',
    alt:"Buyable Instagram",
  },
  {
    iconImage: IconLinkedin,
    url: 'https://www.linkedin.com/company/buyableapp',
    alt:"Buyable Linkedin",
  },

];



export { socialIcons };