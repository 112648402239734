import React from "react";
import Blogo from "../assets/images/mobile-brand-logos/BCorpDesktop.png";
import BlogoMobile from "../assets/images/mobile-brand-logos/BCorpSmall.png";
import EWGlogo from "../assets/images/mobile-brand-logos/EWGVerifiedDesktop.png";
import EWGlogoMobile from "../assets/images/mobile-brand-logos/EWGSmall.png";
import ALogo from "../assets/images/A+.svg";
import APlus from "../assets/images/brand-page/bbbnew.png";
import A from "../assets/images/A.svg";
import Aminus from "../assets/images/A-.svg";
import BPlus from "../assets/images/brand-page/svgs/B+.svg";
import B from "../assets/images/B.svg";
import BMinus from "../assets/images/B-.svg";
import CPlus from "../assets/images/C+.png";
import C from "../assets/images/C.svg";
import CMinus from "../assets/images/C-.png";
import OnlyScore from "../assets/images/score-only.png";
import CrueltyFree from "../assets/images/brand-page/svgs/Cruelty Free.svg";
import CrueltyFreeMobile from "../assets/images/mobile-brand-logos/Cruelty Free.svg";
import Organic from "../assets/images/brand-page/svgs/Organic.svg";
import OrganicMobile from "../assets/images/mobile-brand-logos/Organic mobile.svg";
import Sulfate from "../assets/images/brand-page/svgs/SulfateFree(2).svg";
import SulfateMobile from "../assets/images/mobile-brand-logos/Sulfate Free.svg";
import VeganLogo from "../assets/images/brand-page/svgs/vegan.svg";
import VeganLogoMobile from "../assets/images/mobile-brand-logos/vegan-1.svg";
import GlassDoorText from "../assets/images/brand-page/svgs/glassdor.svg";
import BunnySVG from "../assets/images/mobile-brand-logos/LeapingBunny2.png";
import BunnySVGMobile from "../assets/images/mobile-brand-logos/LeapingBunny.png";
import ParabenFree from "../assets/images/brand-page/svgs/paraban-free.svg";
import ParabenFreeMobile from "../assets/images/mobile-brand-logos/paraban free.svg";
import PlantBasedMobile from "../assets/images/brand-page/petcare/plant based mobile.png";
import PlantBased from "../assets/images/brand-page/petcare/plant based.png";
import StoredInFreezerMobile from "../assets/images/brand-page/petcare/stored in freezer mobile.png";
import StoredInFreezer from "../assets/images/brand-page/petcare/stored in freezer.png";
import StoredInRefrigeratorMobile from "../assets/images/brand-page/petcare/stored in fridge mobile.png";
import StoredInRefrigerator from "../assets/images/brand-page/petcare/stored in fridge.png";
import Vegan from "../assets/images/brand-page/svgs/vegan(1).svg";
import VeganMobile from "../assets/images/mobile-brand-logos/vegan.svg";
import GlutenFreeProducts from "../assets/images/brand-page/svgs/GlutenFree.svg";
import GlutenFree from "../assets/images/brand-page/svgs/Gluten-Free.svg";
import GlutenFreeMobile from "../assets/images/mobile-brand-logos/GlutenFree-1.svg";
import DairyFree from "../assets/images/brand-page/svgs/Dairy-freeOnly.svg";
import DairyFreeMobile from "../assets/images/mobile-brand-logos/Dairy free-1.svg";
import DairyFreeProducts from "../assets/images/brand-page/svgs/DairyFree.svg";
import USDA from "../assets/images/mobile-brand-logos/USDADesktop.png";
import USDAMobile from "../assets/images/mobile-brand-logos/USDAMobile.png";

const icon = (size, iconMobile, iconDesktop, name) => (
  <div>
    {size.width < 992 ? (
      <img src={iconMobile} alt={name} />
    ) : (
      <img src={iconDesktop} alt={name} />
    )}
  </div>
);

export default function RatingsSection({
  type = "",
  company,
  companyGlobalRatings,
  size,
  appearance="normal"
}) {

  return (
    <div className="Ratings">
      {appearance && appearance==="product" && <h2 className="GlobalRatings__Title">{company.name} Certifications</h2>}
      <div className="GlobalRatings__Container GlobalRatings__Brand">
        <h2 className="title-brand">Global Certifications</h2>

        <div className="Ratings">
          {companyGlobalRatings &&
            companyGlobalRatings.map((rating) => (
              <>
                {rating.name === "B-Corp Certified" && rating.present && (
                  <div className="Logo__Container Logo__Container--full Logo__Container--blogo">
                    {size.width < 992 ? (
                      <img src={BlogoMobile} alt={rating.name} />
                    ) : (
                      <img src={Blogo} alt={rating.name} />
                    )}
                  </div>
                )}
                {rating.name === "EWG Certified" && rating.present && (
                  <div className="Logo__Container Logo__Container--ewg">
                    {size.width < 992 ? (
                      <img src={EWGlogoMobile} alt={rating.name} />
                    ) : (
                      <img src={EWGlogo} alt={rating.name} />
                    )}
                  </div>
                )}

                {rating.name === "BBB Accredited" && rating.present && (
                  <div className="Logo__Container Logo__Container__Accredited">
                    {companyGlobalRatings.map((rating) => (
                      <>
                        {rating.name === "BBB Score" && rating.score === 1 && (
                          <img src={ALogo} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 2 && (
                          <img src={A} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 3 && (
                          <img src={Aminus} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 4 && (
                          <img src={BPlus} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 5 && (
                          <img src={B} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 6 && (
                          <img src={BMinus} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 7 && (
                          <img src={CPlus} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 8 && (
                          <img src={C} alt={rating.name} />
                        )}
                        {rating.name === "BBB Score" && rating.score === 9 && (
                          <img src={CMinus} alt={rating.name} />
                        )}
                      </>
                    ))}

                    <img src={APlus} alt="logo" className="accredited-logo" />
                  </div>
                )}

                {(!rating.name === "BBB Accredited" ||
                  (rating.name === "BBB Accredited" && !rating.present)) && (
                  <>
                    {companyGlobalRatings.map((rating) => (
                      <>
                        {rating.name === "BBB Score" && rating.score === 1 && (
                          <div className="Logo__Container">
                            <img src={ALogo} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 2 && (
                          <div className="Logo__Container">
                            <img src={A} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 3 && (
                          <div className="Logo__Container">
                            <img src={Aminus} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 4 && (
                          <div className="Logo__Container">
                            <img src={BPlus} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 5 && (
                          <div className="Logo__Container">
                            <img src={B} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 6 && (
                          <div className="Logo__Container">
                            <img src={BMinus} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 7 && (
                          <div className="Logo__Container">
                            <img src={CPlus} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 8 && (
                          <div className="Logo__Container">
                            <img src={C} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                        {rating.name === "BBB Score" && rating.score === 9 && (
                          <div className="Logo__Container">
                            <img src={CMinus} alt="" />
                            <img src={OnlyScore} alt="logo" />
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}

                {rating.name === "Glassdoor Rating" && rating.score && (
                  <div className="Logo__Container Logo__Container__Glassdoor">
                    <p>{rating.score.toFixed(1)}</p>

                    <img src={GlassDoorText} alt="logo" />
                  </div>
                )}
              </>
            ))}
        </div>
      </div>

      {type === "pet-care" && (
        <div className="CategoryRatings__Container CategoryRatings__Container--pet">
          <h2>Pet Care Certifications</h2>
          <div className="Ratings">
            {company.categories[0]?.ratings.map(({ name }) => (
              <>
                {name === "Vegan" &&
                  icon(size, VeganLogoMobile, VeganLogo, name)}

                {name === "Organic" && icon(size, OrganicMobile, Organic, name)}
                {name === "Gluten Free Products Available" && (
                  <div>
                    <img src={GlutenFreeProducts} alt={name} />
                  </div>
                )}

                {name === "Gluten Free" &&
                  icon(size, GlutenFreeMobile, GlutenFree, name)}
                {name === "Dairy Free" &&
                  icon(size, DairyFreeMobile, DairyFree, name)}
                {name === "Dairy Free Products Available" && (
                  <div>
                    <img src={DairyFreeProducts} alt={name} />
                  </div>
                )}
                {name === "Sulfate Free" &&
                  icon(size, SulfateMobile, Sulfate, name)}
                {name === "Plant-Based" &&
                  icon(size, PlantBasedMobile, PlantBased, name)}
                {name === "Stored in Refrigerator" &&
                  icon(
                    size,
                    StoredInRefrigeratorMobile,
                    StoredInRefrigerator,
                    name
                  )}
                {name === "Stored in Freezer" &&
                  icon(size, StoredInFreezerMobile, StoredInFreezer, name)}
                   {name === "Paraban Free" &&
                  icon(size, ParabenFreeMobile, ParabenFree, name)}

              </>
            ))}
          </div>
        </div>
      )}

      {type === "beauty" && (
        <div className="CategoryRatings__Container CategoryRatings__Container--beauty">
          <h2>Beauty Certifications</h2>
          <div
            className={`Ratings Ratings--${
              company.categories[0]?.ratings.filter(({ id }) => id !== 8).length
            }`}
          >
            {company.categories[0]?.ratings.map(({ name }) => (
              <>
                {name === "Leaping Bunny Certified" && (
                  <div className="BunnyContainer">
                    {size.width < 992 ? (
                      <img src={BunnySVGMobile} alt={name} />
                    ) : (
                      <img
                        src={BunnySVG}
                        alt={name}
                        style={{ maxWidth: "198px" }}
                      />
                    )}
                  </div>
                )}
                {name === "Cruelty Free" &&
                  icon(size, CrueltyFreeMobile, CrueltyFree, name)}
                {name === "Paraben Free" &&
                  icon(size, ParabenFreeMobile, ParabenFree, name)}

                {name === "Vegan Products Available" &&
                  icon(size, VeganMobile, Vegan, name)}

                {name === "Organic" && icon(size, OrganicMobile, Organic, name)}
                {name === "Sulfate Free" &&
                  icon(size, SulfateMobile, Sulfate, name)}
                {name === "Vegan" &&
                  icon(size, VeganLogoMobile, VeganLogo, name)}
              </>
            ))}
          </div>
        </div>
      )}
      {type === "food" && (
        <div className="CategoryRatings__Container CategoryRatings__Container--food">
          <h2>Food Certifications</h2>
          <div
            className={`Ratings Ratings--${
              company.categories[0]?.ratings.filter(
                ({ id }) => id !== 3 && id !== 23
              ).length
            }`}
          >
            {company.categories[0]?.ratings.map(({ name }) => (
              <>
                {name === "Vegan Products Available" && (
                  <div>
                    <img src={Vegan} alt={name} />
                  </div>
                )}

                {name === "Organic" && icon(size, OrganicMobile, Organic, name)}

                {name === "Vegan" &&
                  icon(size, VeganLogoMobile, VeganLogo, name)}
                {name === "Gluten-Free Products Available" && (
                  <div>
                    <img src={GlutenFreeProducts} alt={name} />
                  </div>
                )}
                {name === "Gluten-Free" &&
                  icon(size, GlutenFreeMobile, GlutenFree, name)}
                {name === "Dairy-Free" &&
                  icon(size, DairyFreeMobile, DairyFree, name)}
                {name === "Dairy-Free Products Available" && (
                  <div>
                    <img src={DairyFreeProducts} alt={name} />
                  </div>
                )}

                {name === "USDA Certified" && (
                  <div className="usda">
                    {size.width < 992 ? (
                      <img src={USDAMobile} alt={name} />
                    ) : (
                      <img src={USDA} alt={name} />
                    )}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
