import React from "react";

export default function AppStoreSection({
  icon,
  heading,
  paragraph,
  alt = "",
  href = "",
}) {
  return (
    <section className="AppStoreSection__Container">
      <h2>{heading}</h2>
      <p>{paragraph}</p>
      <a href={href}
                    target="_blank"
                    rel="noreferrer">
        <img src={icon} alt={alt} />
      </a>
    </section>
  );
}
